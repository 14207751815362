.paymenttype {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.paymenttype>div {
  box-sizing: border-box;
  height: 109px;
  width: 344px;
  border: 1px solid #e2e1ee;
  border-radius: 6px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  padding: 0 10px;
}

.feee {
  box-sizing: border-box;
  height: 78px;
  width: 100%;
  border: 0.77px solid #ffb802;
  border-radius: 4px;
  background-color: #fffcf3;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.feee>p {
  color: #000000;
}

.creditcard {
  box-sizing: border-box;
  height: 61px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dfe4ec;
  border-radius: 4px;
  background-color: #fffffe;
  padding: 0 1rem;
}

.setshowmodel-main-div {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.paymenttype>div>h5 {
  margin: 0;
}

.response-main-data {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
}

.Method-Health-card {
  display: flex;
  gap: 1rem;

  width: 100%;
}

@media screen and (max-width: 1125px) {
  .paymenttype>div {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .Method-Health-card {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 616px) {

  .creditcard,
  .feee {
    width: 100%;
  }

  .paymenttype>div {
    height: 100px;
  }
}

@media screen and (max-width: 428px) {
  .setshowmodel-main-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .Method-Health-card {
    flex-direction: column;
    display: flex;

    gap: 1rem;
    width: 100%;
  }
}