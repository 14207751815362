.ForgotPassword-main-section {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

.Profilesidebar-main-div-section-all {
  display: flex;
  justify-content: center;
}

.ForgotPassword-main-div {
  display: flex;
  width: 100%;
}

.ForgotImg-img {
  width: 800px;
  height: 100%;
  object-fit: cover;
}

.Forgot-Password-main-section {
  width: 100%;
  height: 100%;
}

.Forgot-Password-child-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.forgot-image12 {
  width: 700px;
  height: 600px;
}

.group28-img-22 {
  width: 180px;
  height: 95px;
}

.forgot-image-inner-div {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 80%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.dolar-span-text {
  color: #ffffff;
  font-size: 30px;
  font-weight: 400;
  width: 400px;
}

.back-arro-span45-parent-div {
  align-self: flex-start;
  position: relative;
  bottom: 8.5rem;
  padding-left: 1rem;
}

.back-arro-span45-div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.back-span-90 {
  font-size: 15px;
  font-weight: 700;
  color: #1e2d38;
}

.width-percentage-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  height: 100%;

  position: relative;
}

.Forgot-Password-span12 {
  font-size: 27px;
  font-weight: 700;
  color: #1e2d38;
}

.LoginLogo-mtd-logo {
  margin-bottom: 1rem;
}

.Forgot-Password-span1244 {
  font-size: 14px;
  font-weight: 400;
  color: #6f778b;
}

.emai-input-image {
  width: 24px;
  height: 24px;
}

.email-input-div {
  position: relative;
  display: flex;
  align-items: center;
}

.email-input-image-inner-div {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.ForgotImg-blue {
  position: absolute;
  height: 360px;
  width: 740px;
  left: 0;
}

.fielddiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 1rem; */
}

.erorr-he {
  margin-bottom: 1rem;
}

.LoginLogo-div-main-foorget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100000;
  height: 100%;
  position: absolute;
  left: 13rem;
  bottom: 3rem;
}

.Forgot-Password-span1244-div {
  margin-bottom: 1rem;
  text-align: center;
}

.back-90 {
  width: 20px;
}

@media screen and (max-width: 1280px) {
  .LoginLogo-div-main-foorget {
    left: 8rem;
    bottom: 3rem;
  }

  .ForgotImg-img {
    width: 700px;
  }
}

@media screen and (max-width: 1024px) {
  .ForgotPassword-main-div {
    display: flex;
    flex-direction: column;
  }

  .ForgotImg-img {
    width: 100%;
  }

  .LoginLogo-div-main-foorget {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    height: 100%;
    position: absolute;
    left: 17rem;
    bottom: 0rem;
    top: 15rem;
  }

  .ForgotImg-blue {
    position: absolute;
    height: 460px;
    width: 100%;
    left: 0;
    bottom: 0;
    top: 19rem;
  }

  .Forgot-Password-span1244 {
    font-size: 18px;
  }

  .Forgot-Password-span1244-div {
    width: 100%;
  }
}

@media screen and (max-width: 912px) {
  .ForgotPassword-main-div {
    display: flex;
    flex-direction: column;
  }

  .ForgotImg-img {
    width: 925px;
  }

  .LoginLogo-div-main-foorget {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    height: 100%;
    position: absolute;
    left: 17rem;
    bottom: 0rem;
    top: 15rem;
  }

  .ForgotImg-blue {
    position: absolute;
    height: 460px;
    width: 925px;
    left: 0;
    bottom: 0;
    top: 19rem;
  }

  .Forgot-Password-span1244 {
    font-size: 18px;
  }

  .Forgot-Password-span1244-div {
    width: 400px;
  }
}

@media screen and (max-width: 820px) {
  .ForgotPassword-main-div {
    display: flex;
    flex-direction: column;
  }

  .ForgotImg-img {
    width: 825px;
  }

  .LoginLogo-div-main-foorget {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    height: 100%;
    position: absolute;
    left: 14rem;
    bottom: 0rem;
    top: 15rem;
  }

  .ForgotImg-blue {
    position: absolute;
    height: 360px;
    width: 825px;
    left: 0;
    bottom: 0;
    top: 17rem;
  }

  .Forgot-Password-span1244 {
    font-size: 18px;
  }

  .Forgot-Password-span1244-div {
    width: 400px;
  }
}

@media screen and (max-width: 768px) {
  .ForgotPassword-main-div {
    display: flex;
    flex-direction: column;
  }

  .ForgotImg-img {
    width: 770px;
  }

  .LoginLogo-div-main-foorget {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    height: 100%;
    position: absolute;
    left: 13rem;
    bottom: 0rem;
    top: 14rem;
  }

  .ForgotImg-blue {
    position: absolute;
    height: 260px;
    width: 825px;
    left: 0;
    bottom: 0;
    top: 17rem;
  }

  .Forgot-Password-span1244 {
    font-size: 18px;
  }

  .Forgot-Password-span1244-div {
    width: 400px;
  }
}

@media screen and (max-width: 540px) {
  .LoginLogo-div-main-foorget {
    left: 5rem;
    bottom: rem;
  }

  .ForgotImg-img {
    display: none;
    height: 200px;
    width: 200px;
  }

  .ForgotImg-blue {
    height: 500px;
    width: 540px;
    top: 16rem;
  }

  .LoginLogo-div-main-foorget {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 7rem;
  }

  .Forgot-Password-span1244-div {
    width: 250px !important;
  }
}

@media screen and (max-width: 428px) {
  .LoginLogo-div-main-foorget {
    left: 5rem;
    /* bottom: rem; */
  }

  .ForgotImg-img {
    display: none;
    height: 200px;
    width: 200px;
  }

  .ForgotImg-blue {
    height: 500px;
    width: 540px;
    top: 6rem;
  }

  .ForgotPassword-main-div > :nth-child(1) {
    display: flex;
    flex-direction: column;
    left: 0rem;
    justify-content: center;
  }

  .LoginLogo-div-main-foorget {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    height: 100%;
    position: absolute;
    left: 7rem;
    top: -3rem;
  }

  .Forgot-Password-span1244-div {
    width: 250px !important;
  }
}

@media screen and (max-width: 415px) {
  .LoginLogo-div-main-foorget {
    left: 5rem;
    bottom: rem;
  }

  .ForgotImg-img {
    display: none;
    height: 200px;
    width: 200px;
  }

  .ForgotImg-blue {
    height: 500px;
    width: 420px;
    top: 0rem;
  }

  .ForgotPassword-main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .LoginLogo-div-main-foorget {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    height: 100%;
    position: absolute;
    left: 3rem;
    bottom: 3rem;
  }
  .Forgot-Password-span1244-div {
    width: 250px !important;
  }
}

@media screen and (max-width: 376px) {
  .LoginLogo-div-main-foorget {
    left: 5rem;
    bottom: rem;
  }

  .ForgotImg-img {
    display: none;
    height: 200px;
    width: 200px;
  }

  .ForgotImg-blue {
    height: 300px;
    width: 380px;
    top: 2rem;
  }

  .ForgotPassword-main-div {
    display: flex;
    flex-direction: column;

    justify-content: center;
  }

  .LoginLogo-div-main-foorget {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    height: 100%;
    position: absolute;
    left: 2rem;

    bottom: 1rem;
  }

  .Forgot-Password-span1244-div {
    width: 250px !important;
  }
}

@media screen and (max-width: 360px) {
  .LoginLogo-div-main-foorget {
    left: 5rem;
    bottom: rem;
  }

  .ForgotImg-img {
    display: none;
    height: 200px;
    width: 200px;
  }

  .ForgotImg-blue {
    height: 300px;
    width: 380px;
    top: 4rem;
  }

  .ForgotPassword-main-div {
    display: flex;
    flex-direction: column;

    justify-content: center;
  }

  .LoginLogo-div-main-foorget {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    height: 100%;
    position: absolute;
    left: 1.5rem;

    bottom: 1rem;
  }

  .Forgot-Password-span1244-div {
    width: 250px !important;
  }
}
