.faq-main-section {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.faq-main-div {
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: center;
}



.accordion-button:not(.collapsed)::after {
    display: none;
}

.accordion-button::after {
    display: none;
}

.main_Qus_div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.AiOutlineMinusSquare-main-icon {
    height: 25px;
    width: 25px;
    color: #3620FF;
}

.AiOutlinePlusSquare-main-icon {
    height: 25px;
    width: 25px;
}

@media screen and (max-width: 884px) {
    .faq-main-div {
        width: 90%;
    }
}

@media screen and (max-width: 428px) {
    .faq-main-div {
        width: 90%;
    }
}