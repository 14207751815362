.appointment-main-div-appointment {
    height: 34px;
    width: 343px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffb802;
    color: #282731;
    font-size: 16px;
    cursor: pointer;

}

.div-Block {
    height: 30px;
    width: 30px;
    color: #65646c;
    background-color: #9c9ba0;
    border-radius: 5px;
}

.slote-card-head {
    display: flex;
    gap: 1rem;
    border-radius: 7px;
    padding: 2rem;
    /* border: 1px solid #71767f; */
    width: 100%;
}

.div-Virtual {
    height: 30px;
    width: 30px;
    color: #65646c;
    background-color: #9982f3;
    border-radius: 5px;
}

.Block-Virtual-Person {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2rem;
}

.div-Person {
    height: 30px;
    width: 30px;
    color: #65646c;
    background-color: #e6f778;
    border-radius: 5px;
}

.Block-span-div {
    display: flex;

    align-items: center;
    gap: 0.5rem;
}

.Appointment-main-div-Time {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

}

.days-button {
    /* Your existing styles for the button */
}

.inperson-bg {
    background-color: #e6f778;
    color: white;
}

.virtual-bg {
    background-color: #9982f3;
    color: white;
}

.block-bg {
    background-color: gray;
    color: white;
}

.disabled-slot {
    background-color: red;
    /* Change this to the desired color for disabled slots */
    color: #ccc;
    /* Change this to the desired text color for disabled slots */
    cursor: not-allowed;
}

.gray-bg {
    background-color: gray;
}