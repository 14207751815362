.UpcomingAppointment2-main-section {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
}

.UpcomingAppointment2-main-div {
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.UpcomingAppointment2-thord-div {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;
}

.h-d-s-line {
    height: 1px;
    width: 100%;
    background-color: #8e8f91;
    margin-top: 2rem;
}

.Consultation-type-Video {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
}

.Video-Chat-Consult-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Video-main-span {
    color: #E1A40A;
    font-size: 16px;
    font-weight: 600;
}

.Consultation-type-sapn {

    color: #16151D;
    font-weight: 700;
    font-size: 17px;

}

.Today-main-date-span {
    color: #16151D;
    font-size: 14px;
}

.Reason-for-appointment-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
}

.Method-of-Card {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Payment-for-appointment-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Lorem-d-s-line {
    height: 0.5px;
    width: 100%;
    background-color: #8e8f91;
    margin-top: 2rem;
}

.Reschedule-Start-div {
    display: flex;
    gap: 3rem;
}

.Reschedule-btn {
    box-sizing: border-box;
    height: 45px;
    width: 164px;
    outline: none;
    border: 1px solid #DBD9E9;
    border-radius: 4px;
    background-color: #FFFFFF;
}

.Reschedule-Start-btn {
    height: 45px;
    width: 164px;
    border: none;
    outline: none;
    border-radius: 4px;
    background-color: #3620FF;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;

}

.thartin-span-div {
    color: #3620FF;
    font-size: 17px;
    font-weight: 700;
}

.Updata-main-img {
    border-radius: 6px;
    object-fit: cover;
    height: 70px;
    width: 70px;
}

.img-name-field-main {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.edit-icon-mi {
    display: flex;
    justify-content: space-between;
}

.General-span-Medicine-span {
    display: flex;
    flex-direction: column;
}