.Height-Weight-main-div {
  display: flex;
  flex-direction: column;
}

.Height-awy-main-div {
  display: flex;
  flex-direction: column;
}

.Height-w-bmi-div {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.hagghit-span-and {
  color: #16151d;
  font-weight: 700;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
}
