.ReferralDoctor-main-section {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.ReferralDoctor-main-div {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    padding: 1rem;
}

.Subjective-HPI-2 {
    /* width: 100%; */
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    outline: none;
}

.ReferralDoctor-Register-karo {
    height: 45px;
    width: 343px;
    border: none;
    outline: none;
    border-radius: 6px;
    background: #3620FF;
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
}






.my-component-container {
    max-width: 800px;
    margin: 0 auto;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.dropzone-container {
    width: 100%;
    height: 100px;
    border: 2px dashed #ccc;

    text-align: center;
    cursor: pointer;
}

.pdf-viewer-container {
    width: 100%;
    height: 500px;
    margin-top: 20px;
}

.uploaded-files-container {
    margin-top: 20px;
}

.image-upload-container {
    margin-top: 20px;
}

.overflowing-add-images {
    display: flex;
    flex-wrap: wrap;
}

.image-item {
    margin-right: 10px;
    margin-bottom: 10px;
}

.uploaded-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
}

.delete-button {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 6px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.ok-jii {
    height: 30px;
    width: 30px;
    border: 1px solid black;
    background-color: #dc3545;
}

.path-file {
    font-size: 20px;
    color: black;
}

.FiPlusCircle-main-div {
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #cfcfcf;
    box-shadow: 0px 1.5px #cfcfcf;
    border-radius: 5px;
}

.FiPlusCircle-amin {
    height: 25px;
    width: 25px;
    color: blue;
}

.FaRegFileAlt-amin {
    height: 25px;
    width: 25px;

}

.FiPlusCircle-and-FaRegFileAlt {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.handleRemovePdf-main {
    position: relative;
}

.MdCancel-main {
    position: absolute;
    top: -5px;
    right: -5px;
    height: 20px;
    width: 20px;
}