.isSelected-main-div {
    display: flex;
    justify-content: space-between;
}

.Select-main-section {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1rem;
}