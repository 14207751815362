.profile-age-div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Email-and-dorothy-price {
  display: flex;
  justify-content: space-between;
}

.age-main-span-add {
  color: #16151d;
  font-size: 16px;
  font-weight: bold;
}

.Professional-Licence-div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  /* padding-bottom: 5px; */
}

.GeneralInfo-main-Change {
  height: 45px;
  width: 339px;
  border-radius: 4px;
  background-color: #3620ff;
  color: #ffffff;
  font-size: 15px;
  border: none;
  outline: none;
  font-weight: bold;
}

.GeneralInfo-main-Change-btn-div {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 3rem;
}

.line-hover-line {
  display: flex;
}

.all-outlet-span {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  cursor: pointer;
}
