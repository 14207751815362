.back-skip-point {
    color: #3620FF;
    font-size: 17px;
    letter-spacing: 0;
}

.navigate-back-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
}

.text-Bank-fullname {
    background: #ffffff;
    outline: none;
    border: 1px solid #d1d1d1 !important;
    border-radius: 4px;
    height: 45px;
    width: 343px;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 0.7rem;
}

.i-t-a-Bank-itm {
    outline: none;
    height: 40px;
    width: 303px;
    border: none;
}

.allNumber-input-div-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1000;

}

.YourProfile-Code-next-blue {
    width: 100%;
    height: 475px;
    position: absolute;
    bottom: -7rem;
}