.Dorothy-extra-div-svg-book {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.FindMD-main-section-DoctorBook {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
}