.Health-lyer-dark-main-div {
    position: relative;
}

.dark-docter {
    height: 100px;
    width: 100px;
    border-radius: 7px;
}

.dark-lyer-docter {
    position: absolute;
    top: 0;
    height: 100px;
    width: 100px;
    border-radius: 7px;
}

.dark-Lockyer-docter {
    position: absolute;
    top: 2.2rem;
    left: 2.2rem;
    right: 0;
    height: 30px;
    width: 30px;
    border-radius: 7px;
}

.Detail-main-butn {
    box-sizing: border-box;
    height: 30px;
    width: 107px;
    border: 0.77px solid #3400FF;
    border-radius: 4px;
    background-color: #FFFFFF;
    outline: none;
    color: #3400FF;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
}

.Start-button-main {
    color: #FFFFFF;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    border: none;
    height: 30px;
    width: 107px;
    border-radius: 4px;
    background-color: #3620FF;
}

.dono-he-buton-div {
    display: flex;
    gap: 1rem;
}

.Detail-blur-main-butn {
    height: 30px;
    width: 107px;
    border: 0.77px solid #3400FF;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #3400FF;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;

}

.Start-blur-button-main {
    color: #FFFFFF;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    border: none;
    height: 30px;
    width: 107px;
    border-radius: 4px;
    background-color: #3620FF;
}