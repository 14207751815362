.all-input-areya {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Btn-Register-karo-CreateProfileDoctor {
  height: 45px;
  width: 339px;
  border: none;
  outline: none;
  border-radius: 6px;
  background: #3620ff;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.input-date-main-div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Age-main-dive-api {
}

.Date-of-Birth-input {
  padding: 1rem;
  outline: none;
  height: 45px;
  width: 310px !important;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff !important;
  color: #9aa1ab;
  letter-spacing: 0;
  /* display: flex; */
  /* flex-direction: row-reverse; */
  gap: 1rem;
}

.text-Phone-fullname {
  background: #ffffff;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  height: 45px;
  width: 343px;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.i-t-a-Phone-itm {
  outline: none;
  height: 40px;
  width: 290px;
  border: none;
}

.full-and-last-name-creat {
  display: flex;
  gap: 1rem;
}

.mastu-to-eroor-FullName {
  color: red;
  width: 140px;
  font-size: 15px;
  font-weight: 400;
}

.Date-of-Birth-input-physician {
  padding: 1rem;
  outline: none;
  height: 45px;
  width: 343px !important;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff !important;
  color: #9aa1ab;
  letter-spacing: 0;
}

.Full-all-Name-input-bug {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
}

.Fax-img-main {
  height: 20px;
  width: 20px;
}

.CreateProfileDoctor-next-blue {
  width: 100%;
  height: 500px;
  position: fixed;
  bottom: 0rem;
}

.all-input-CreateProfileDoctor-div-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1000;
}

.text-CreateProfileDoctor-fullname {
  background: #ffffff;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  height: 45px;
  width: 164px;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.Female-btn-main {
  height: 45px;
  width: 164px;
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
  color: #9aa1ab;
  font-size: 14px;
  font-weight: 600;
}

.Female-btn-mainactive {
  height: 45px;
  width: 164px;
  background-color: #ffb802;
  border-radius: 4px;
  border: none;
  outline: none;
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.i-t-a-move-host-itm {
  outline: none;
  height: 40px;
  width: 124px;

  border: none;
}

@media screen and (max-width: 884px) {
  .i-t-a-move-host-itm {
    height: 40px;
    width: 303px;
  }

  .text-CreateProfileDoctor-fullname {
    height: 45px;
    width: 343px;
  }

  .all-input-CreateProfileDoctor-div-main {
    top: 10rem;
  }
}

@media screen and (max-width: 768px) {
  .i-t-a-move-host-itm {
    height: 40px;
    width: 303px;
  }

  .text-CreateProfileDoctor-fullname {
    height: 45px;
    width: 343px;
  }
  .full-and-last-name-creat {
    flex-direction: column;
  }
}

@media screen and (max-width: 428px) {
  .i-t-a-move-host-itm {
    height: 40px;
    width: 303px;
  }

  .text-CreateProfileDoctor-fullname {
    height: 45px;
    width: 343px;
  }

  .CreateProfileDoctor-next-blue {
    bottom: -29rem;
  }
}
