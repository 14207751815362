.PatientName-awy-main-div {
    display: flex;
    justify-content: space-between;
    width: 300px;
}

.Pending-main-div-complet {
    background-color: rgb(255, 204, 0);
    height: 25px;
    color: white;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.complet-main-div-Pending {
    background-color: rgb(30, 163, 32);
    height: 25px;
    color: white;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}