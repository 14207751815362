.Country-main-input {
    display: flex;
    flex-direction: column;

}

.Speciman-main-Details-div {
    width: 343px;
    background-color: rgb(165, 134, 197);
    height: 55px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.Factors-Type-span-div {
    width: 343px;
    background-color: rgb(253, 174, 63);
    height: 55px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.Ethnicity-main-Details-div {
    width: 343px;
    background-color: rgb(165, 134, 197);
    height: 45px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.Birth-of-Birth-input-LMNP {
    padding: 1rem;
    outline: none;
    height: 45px;
    width: 343px;
    border: 1px solid #bebebe;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #9AA1AB;
    letter-spacing: 0;
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
}

.specimen-Type-span-div {
    width: 343px;
    background-color: rgb(142, 145, 156);
    height: 45px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.check-Type-span-div {
    width: 343px;
    background-color: rgb(253, 18, 18);
    height: 45px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;

}

.Information-Type-span-div {
    width: 343px;
    background-color: rgb(41, 198, 216);
    height: 55px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}