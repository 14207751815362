.PrescriptionDetail-main-section {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.PrescriptionDetail-main-div {
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  align-items: center;
}

.PrescriptionDetail-thard {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  /* justify-content: space-between; */
  margin-top: 1rem;
}

.PrescriptionDetail-main-line {
  height: 1px;
  width: 100%;
  background-color: #e5e9f6;
}

.time-3-sapn {
  color: #16151d;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
}

.Lorem-main-span {
  color: #16151d;

  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
}

.mainrpecriptiondetail {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.checkboxpre {
  transform: scale(1.5);
}

.paybtn {
  background-color: #3620ff !important;
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 5px;
  color: #ffffff;
}

.paybtn:disabled {
  background-color: grey;
}

@media screen and (max-width: 884px) {
  .PrescriptionDetail-main-div {
    width: 90%;
  }
}

@media screen and (max-width: 528px) {
  .PrescriptionDetail-main-div {
    width: 90%;
  }

  /* .PrescriptionDetail-main-section {
    padding: 10px 10px;
  } */
}
