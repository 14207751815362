.circalcontainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding-bottom: 1rem; */
}

.homeheader {
  background-color: #282731;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.homeheader > :nth-child(1) {
  display: flex;
  align-items: center;
  gap: 10px;
}

#headername {
  color: #ffffff;
}

.homeheader > :nth-child(1) > img {
  width: 40px;
  border-radius: 50%;
  height: 40px;
}

.homeheader > :nth-child(1) > div > span {
  font-size: 13px;
}

.circalcontainer > :nth-child(2) {
  width: 60%;
  display: none;
}

.circalcontainer > :nth-child(2) > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.herosection {
  position: relative;
}

.herosection > p {
  position: absolute;
  bottom: 30%;
  width: 60%;
  color: #282731;
  padding: 0 1rem;
}

.circlediv {
  position: relative;
  /* border: 1px solid red; */
  padding-top: 2rem;
}

.circlediv > div {
  position: absolute;
  cursor: pointer;
  line-height: 20px;
}

.circlediv > div > span {
  font-size: 13px;
  line-height: 10px !important;
}

/* Profile */
.circlediv > :nth-child(2) {
  top: 16%;
  left: 44%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}

.Health-main-card-span {
  height: 20px;
  width: 20px;
  color: blue;
}

.Health-main-card-span2 {
  height: 20px;
  width: 20px;
  color: white;
}

/*   Health  Data */
.circlediv > :nth-child(3) {
  top: 32%;
  left: 71%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}

/* Settings */
.circlediv > :nth-child(4) {
  top: 65%;
  left: 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}

/*  Find MD */
.circlediv > :nth-child(5) {
  top: 33%;
  left: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}

/* Prescription  Renewal */
.circlediv > :nth-child(6) {
  top: 63%;
  right: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}

/* Health Card Payment/Insurance */
.circlediv > :nth-child(7) {
  bottom: 6%;
  right: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}

/*   My Appointment */
.circlediv > :nth-child(8) {
  top: 50%;
  left: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}

.bottombuttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.bottombuttons > button {
  box-sizing: border-box;
  height: 45px;
  width: 164px;
  /* border: 2px solid #dbd9e9; */
  border-radius: 4px;
  background-color: #ffffff;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.bottombuttons > button > img {
  width: 25px;
  color: #282731;
}

.homeheader > :nth-child(2) {
  position: absolute;
  right: 10px;
  top: 20px;
  height: 20px;
  width: 20px;
  display: grid;
  place-items: center;
  cursor: pointer;
  color: #282731;
  font-weight: 700;
  border-radius: 50%;
  font-size: 10px;
}

@media screen and (max-width: 990px) {
  .circalcontainer > :nth-child(2) {
    width: 100%;
    display: block;
  }
}
