.hagghit-span-and {
    color: #FFFFFF;
    font-family: "Be Vietnam";
    font-size: 20px;
    font-weight: bold;

}

.Dorothy-main-span {
    color: #B6B4C0;

    font-size: 13px;

}