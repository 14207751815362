.Reject-Accept-div {
  display: flex;
  gap: 1rem;
}

.Reject-sand-btn {
  box-sizing: border-box;
  height: 30px;
  width: 107px;
  border: 0.77px solid #3400ff;
  border-radius: 4px;
  background-color: #ffffff;
  color: #3400ff;
  font-size: 13px;
  font-weight: bold;
  outline: none;
}

.Accept-sand-btn {
  height: 30px;
  width: 107px;
  border-radius: 4px;
  background-color: #3620ff;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
}
.FindMD-main-section > h1 {
  margin-top: 2rem;
}

@media screen and (max-width: 768px) {
  .Reject-sand-btn {
    height: 30px;
    width: 87px;
  }

  .Accept-sand-btn {
    height: 30px;
    width: 87px;
  }
}

@media screen and (max-width: 429px) {
  .Reject-sand-btn {
    height: 30px;
    width: 87px;
  }

  .Accept-sand-btn {
    height: 30px;
    width: 87px;
  }
}
