.SelectLab-sand-btn {
    height: 30px;
    width: 87px;
    border-radius: 4px;
    background-color: #3620FF;
    outline: none;
    border: none;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;

}