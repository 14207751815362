.SigPad-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.clearSignature-main {
    width: 300px;
    height: 200px;
    border: 2px solid black !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.Save-signatur-and-show {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

}

.Save-signatur-and-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    /* margin: 2rem; */
}


.Done-main-button-sig {
    width: 80%;
    height: 40px;
    background-color: blue;
    color: white;
    border-radius: 6px;
    border: none;
}

.Signature-Undo {
    display: flex;
    gap: 1rem;
    margin-left: 1rem;
    cursor: pointer;
}

.clearSignature-clear-button {}

.saveSignature-main {
    justify-content: center;
    gap: 1rem;
    display: flex;
    align-items: center;
}

.entarsignatur-div {
    width:
        350px;
    height: 150px;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
}

.showSignature-div-ok {
    width:
        100%;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    /* align-items: flex-start;
    justify-content: center; */
    align-items: center;
    margin-bottom: 1rem;

}

.Signature-span-jii {
    color: #16151D;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;

    text-align: center;
}