.AFTER-main-input {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.Patient-main-span-div {
    width: 343px;
    background-color: rgb(164, 131, 188);
    height: 55px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.Other-input-2-answered {
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    outline: none;
    width: 343px;
}

.SYMPTOMS-main-span-div {
    width: 343px;
    background-color: rgb(236, 62, 62);
    height: 45px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.DIAGNOSES-main-span-div {
    width: 343px;
    background-color: rgb(24, 188, 188);
    height: 45px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.MEDICAL-main-span-div {
    width: 343px;
    background-color: rgb(28, 31, 231);
    height: 45px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.DIAGNOSES2-main-span-div {
    width: 343px;
    background-color: rgb(154, 155, 160);
    height: 15px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.Height-Wight-main-input {
    width: 145px;
    height: 45px;
    border-radius: 5px;
    border: 1px solid rgb(205, 197, 197);
    outline: none;
    padding: 1rem;
}

.all-input-and-span {
    display: flex;
    gap: 1rem;
    margin-left: 2rem;
    margin-top: 1rem;
}

.Submit-forms {
    width: 343px;
    background-color: rgb(28, 31, 231);
    height: 45px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
    border: none;
}