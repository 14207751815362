.No-contact-main-btn {
    width: 100px;
    background-color: blue;
    border: none;
    height:
        35px;
    color: white;
    border-radius: 5px;
}

.No-contact-main-btn-div {
    display: flex;
    gap: 1rem;
}