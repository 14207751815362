.familymembers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  cursor: pointer;
  gap: 1rem;
}

.Main-full-name-and-last {
  display: flex;
  gap: 1rem;
}

.FullName-cover-fullname {
  background: #ffffff;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  height: 45px;
  width: 205px;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.FullName-to-eroor {
  display: flex !important;
  align-items: flex-start !important;
  color: red !important;
}

.Btn-Next-karo-format {
  width: 420px;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 6px;
  background: #3620ff;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.Female-btn-mainactive-Addfemaliy {
  height: 45px;
  width: 205px;
  background-color: #ffb802;
  border-radius: 4px;
  border: none;
  outline: none;
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.Female-btn-main-Addfemaliy {

  height: 45px;
  width: 205px;
  background-color: white;
  /* background-color: #FFB802; */
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
  color: #9aa1ab;
  font-size: 14px;
  font-weight: 600;
}


.add-kiya-muja-new-membar {

  object-fit: cover;
  width: 120px;
  height: 120px;
  border-radius: 100px;
}

.handleBlur-green {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Age-of-Birth-input-bg {
  padding: 1rem;
  outline: none;
  height: 45px;
  width: 420px !important;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff !important;
  color: #9aa1ab;
  /* letter-spacing: 0; */
  gap: 1rem;
}

.member-select-option-inl {
  width: 420px;
  height: 50px;
  border: 1px solid #d1d1d1;
  outline: none;
  border-radius: 6px;
}

.inline-and-male-f {
  /* d-flex align-items-start gap-2 w-100 mt-1; */
  display: flex;
  align-items: self-start;
  gap: 2rem;

}

@media screen and (max-width: 1158px) {
  .familymembers {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 888px) {
  .familymembers {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 540px) {
  .familymembers {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }
}


@media screen and (max-width: 428px) {
  .Main-full-name-and-last {
    display: flex;
    flex-direction: column;
  }

  .FullName-cover-fullname {
    width: 320px;
  }

  .Age-of-Birth-input-bg {
    width: 320px !important;
  }

  .member-select-option-inl {
    width: 320px !important;

  }



  .inline-and-male-f {
    display: flex;
    flex-direction: column;

  }

  .Female-btn-main-Addfemaliy {
    width: 320px !important;
  }
}