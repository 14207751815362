.Xrayultrasound-main-section {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-bottom: 3rem;
}

.Xrayultrasound-main-div {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    align-items: center;
    justify-content: center;
}


.Pregnant-yes-no-div {
    display: flex;
    flex-direction: column;


}

.Pregnant-yes-no {
    height: 25px;
    width: 25px;
    outline: none;
    border: 1px solid #E3E3E3;
}

.no-Yes-main-div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.Pregnant-main-yes-no-div {
    display: flex;
    gap: 3rem;
    width: 443px;
}

.Be-answered-area-main {
    display: flex;
    flex-direction: column;
}

.here-input-2-answered {

    padding: 1rem;
    background: #ffffff;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    outline: none;
    width: 443px;
}

.ULTRASOUND-spn {}

.ULTRASOUND-main-span-div {
    width: 343px;
    background-color: rgb(53, 205, 205);
    height: 45px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.ULTRASOUND-section-div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.ULTRASOUND-section-div-MAMMOGRAPHY {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.checkdivmain {
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;
    margin-top: 1rem;
}

.Date-of-Birth-input-Test {
    padding: 1rem;
    outline: none;
    height: 45px;
    width: 443px;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #9AA1AB;
    letter-spacing: 0;
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
}

.GENERAL-main-span {
    font-size: 18px;
    font-weight: 500;
}

.Trimester-text {
    margin-left: 2rem;
}

.XRAY-main-span-div-perant {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.XRAY-main-span-div {
    width: 343px;
    background-color: rgb(127, 95, 167);
    height: 45px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.LOWEREXTREMITIESTO-main-div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 200px;
    gap: 1rem;
    margin-left: 1.2rem;
    margin-top: 1rem;
}

.LOWEREXTREMITIESTO-check {
    display: flex;
    margin-right: 1rem;
    align-items: center;
    gap: 0.5rem;
}

.MAMMOGRAPHY-main-span-div {
    width: 343px;
    background-color: rgb(231, 231, 33);
    height: 45px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.Left-And-Rghit {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    margin-left: 2rem;
}

.Left-Mammogram-div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.Lower-main-lmbs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    flex-direction: column;
}

.Lower-amin-img {
    height: 120px;
    width: 150px;
}

.Left-Lower-Rghit {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;

}

.chack-in-span {
    width: 90%;
    font-size: 17px;
    font-weight: 600;
}

@media screen and (max-width: 884px) {
    .ULTRASOUND-section-div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        width: 90%;

    }

    .media-screen-ULTRASOUND {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .ULTRASOUND-section-div-MAMMOGRAPHY {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        width: 90%;
    }

    .Xrayultrasound-main-div {
        width: 100%;
    }
}


@media screen and (max-width: 768px) {
    .ULTRASOUND-section-div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        width: 90%;

    }

    .media-screen-ULTRASOUND {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .ULTRASOUND-section-div-MAMMOGRAPHY {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        width: 90%;
    }

    .Xrayultrasound-main-div {
        width: 100%;
    }
}

@media screen and (max-width: 429px) {
    .ULTRASOUND-section-div {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
        align-items: center;
        justify-content: center;
    }

    .ULTRASOUND-section-div-MAMMOGRAPHY {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
    }

    .Date-of-Birth-input-Test {
        width: 350px;
    }

    .Pregnant-main-yes-no-div {
        width: 350px;
    }

    .here-input-2-answered {
        width: 350px;

    }

    .Lower-main-lmbs {
        width: 350px;
    }

    .span-you-div {
        width: 100%;
    }


}