.full-and-last-name-edit {
    display: flex;
    gap: 1rem;
}

.Date-of-Birth-input-startDate {
    padding: 1rem;
    outline: none;
    height: 45px;
    width: 343px;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #9AA1AB;
    letter-spacing: 0;
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
}