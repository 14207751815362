.bookappointmentthree {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-bottom: 1rem;
}

.consultation> :nth-child(1) {
  width: 155px;
}

.consultation> :nth-child(2) {
  width: 220px;
}

.selected-date {
  background-color: rgb(19, 19, 186);
  color: white;
}

.consultation,
.reasonbuttons {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  /* border: 1px solid red; */
  width: 100%;
}

.reasonbuttons>div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reasonbuttons>div>div {
  box-sizing: border-box;
  height: 109px;
  width: 344px;
  border: 1px solid #e2e1ee;
  border-radius: 6px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  padding: 0 10px;
}

.reasonbuttons>div>div>p {
  color: #16151d;
  font-size: 10px;
  text-align: center;
}

.bookappointmentthree>div>p {
  color: #16151d;
  font-weight: 700;
  font-size: 20px;
  align-self: flex-start;
  padding: 0px 10px;
}

.bookappointmentthree>div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.bookappointmentthree>div>span {
  /* background-color: #16151d; */
  position: absolute;
}

.item1 {
  top: 17%;
  width: 50px;
  height: 79px;
  border-radius: 10px;
}

.item1female {
  top: 17%;
  width: 54px;
  height: 78px;
  border-radius: 10px;
}

.item2 {
  top: 30%;
  width: 80px;
  height: 135px;
  border-radius: 15px;
}

.femaleitem2 {
  top: 30%;
  width: 70px;
  height: 140px;
  border-radius: 15px;
}

.item3 {
  top: 52%;
  width: 100px;
  height: 290px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.femaleitem4 {
  top: 31%;
  left: 28%;
  width: 25px;
  height: 210px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  transform: rotate(10deg);
}

.item4 {
  top: 30%;
  left: 22%;
  width: 30px;
  height: 210px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  transform: rotate(14deg);
}

.item5 {
  top: 30%;
  right: 22%;
  width: 30px;
  height: 210px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  transform: rotate(-14deg);
}

.femaleitem5 {
  top: 31%;
  right: 28%;
  width: 25px;
  height: 210px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  transform: rotate(-10deg);
}

@media screen and (max-width: 616px) {
  .consultation>div>div {
    width: 144px;
  }

  .bookappointmentthree {
    align-items: flex-start;
  }

  .mobileview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .reasonbuttons>div>div {
    width: 100%;
    height: 150px;
    /* height: auto; */
  }
}