.Doctorimg-main-profile {
    width: 100%;
    height: 500px;
    object-fit: cover;

}

.main-po {
    position: relative;
    margin-bottom: 5rem;
    border-radius: 6px;
}

.Doctorimg-main-sado {
    width: 100%;
    height: 500px;
    object-fit: cover;
    position: absolute;
    bottom: 0;
}

.Daniel-Norman-main {
    left: 1rem;
    position: absolute;
    bottom: 2rem;
    width: 95%;

}

.General-Medicine-span {
    display: flex;
    justify-content: space-between;
    width: 95%;

}

.dr-main-name {
    height: 32px;
    width: 188px;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
}

.kghjklk-main-po {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;

}

.dr-main-name-General {
    color: #FFFFFF;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
}

.font-3-2-2 {
    color: #FFFFFF;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
}

.point-4-span-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.font-3-2-2-dot {
    height: 5px;
    width: 5px;
    color: #FFFFFF;
    background-color: #FFFFFF;
    border-radius: 50px;
}

.time-line-img {
    height: 25px;
    width: 25px;
}

.time-am-pam-main-div {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.time-am-pam-main-div-second {
    width: 90%;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.Toronto-main-line {
    height: 1px;
    width: 100%;
    background-color: #E5E5E5;
}

.time-am-pam-main-div-About {
    width: 90%;
    flex-direction: column;
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
}

.consectetur-main-div {}

.Qualifications-main-span-div {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.Years-of-one-span {
    color: #16151D;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 23px;
}

.Book-DoctorProfile-btn {
    height: 45px;
    width: 339px;
    border-radius: 4px;
    background-color: #3620FF;
    border: none;
    outline: none;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
}