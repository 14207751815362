.error-message-Note {
    color: red;
    font-size: 15px;
}

.Reason-patient-Torso-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;

}