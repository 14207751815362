.Write-main-input-Here {
    height: 45px;
    width: 300px;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    display: flex;
    align-items: center;
    outline: none;
}

.handleInputChange-main-inputValue {
    height: 40px;
    width: 250px;
    outline: none;
    margin-left: 1rem;
    border: none;
}

.alert-icon {
    color: red;
    height: 25px;
    width: 25px;
}

.Add-model-madice {
    z-index: 10000 !important;
}