.bug-solve-and-last {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Volvo-Speciality-value {
  box-sizing: border-box;
  height: 45px;
  width: 343px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
}

.hoooo-so-hoo {
  display: flex;
  gap: 5rem;
  width: 100%;
}

.Information-Street-main-div {
  background: #ffffff;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  height: 45px;
  width: 343px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding: 1rem;
}

.Information-Street-main-div>* {
  width: 100% !important;
}

.LicensingNo-add-more {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
  gap: 0.5rem;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .hoooo-so-hoo {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;

  }
}

@media screen and (max-width: 820px) {
  .hoooo-so-hoo {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

  }
}

@media screen and (max-width: 768px) {
  .hoooo-so-hoo {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

  }
}

@media screen and (max-width:540px) {
  .hoooo-so-hoo {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

@media screen and (max-width: 428px) {
  .hoooo-so-hoo {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}