.BookAnAppointment1-main-section {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.BookAnAppointment1-main-div {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.appointment-main-div {
  height: 34px;
  width: 343px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffb802;
  color: #282731;
  font-size: 16px;
  cursor: pointer;
  display: none;
}

.Select-Doctor-Type-div {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.radio-bttn-div-any {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.type-radio-in {
  height: 22px;
  width: 22px;
}

.Select-Appointment-time-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.time-input-main-he {
  box-sizing: border-box;
  height: 45px;
  width: 343px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
  padding-left: 1rem;
}

.Next-btn-Appointment {
  height: 45px;
  width: 339px;
  border-radius: 4px;
  background-color: #3620ff;
  color: #ffffff;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: bold;
}

.Virtual-Appointment-main-div {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.Person-main-img-Virtual {
  height: 27px;
  width: 27px;
}

.Select-Appointment-type {
  color: #16151d;

  font-size: 14px;
  font-weight: 600;
}

.Available-any-sapn {
  color: #16151d;
  font-size: 13px;
}

.Virtual-and-Person-Appointment-di {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  height: 79px;
  width: 164px;
  border: 1px solid #e2e1ee;
  border-radius: 6px;
  background-color: #ffffff;
}

.Appointment-spacealitty {
  color: #16151d;

  font-size: 12px;
  font-weight: 500;
}

.dono-ka-he-main-div {
  display: flex;
  gap: 2rem;
}

@media screen and (max-width: 428px) {
  .Appointment-spacealitty {
    font-size: 10px;
  }
  .time-input-main-he {
    width: 100%;
  }
}

@media screen and (max-width: 391px) {
  .Appointment-spacealitty {
    font-size: 10px;
  }

  .Reschedule-main-btn {
    width: 315px;
  }
}
