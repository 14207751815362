.Prescription-main-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.Prescription-main-div {
  display: flex;
  justify-content: center;
  width: 80%;
  gap: 2rem;
  height: 100%;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.Enter-Dose-value {
  box-sizing: border-box;
  height: 45px;
  width: 164px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
}

.Enter-Dose-value-Dose {
  box-sizing: border-box;
  height: 45px;
  width: 250px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;

}

.Enter-Dose-value-number {
  box-sizing: border-box;
  height: 45px;
  width: 164px;
  padding: 1rem;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
}

.Enter-Dose-value-Route {
  box-sizing: border-box;
  height: 45px;
  width: 350px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
}

.Duration-add-more {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  width: 100%;
  gap: 0.5rem;
  justify-content: flex-end;
  cursor: pointer;
}

.Patient-main-div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.Medicine-sab-input-span {
  color: #16151d;
  font-size: 17px;
  font-weight: 600;
}

.handleMedicineChange-main {
  width: 350px;
  cursor: pointer;
  outline: none;
  height: 45px;
  border: #e3e3e3 1px solid;
  padding: 1rem;

}