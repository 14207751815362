.Splash-main-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}

.Splash-main-div {
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
}

.bac-garund-div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Set the height of the container as needed */
}

.Homrimg-Splash-screen {
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1;
}

.LOgin-Logo {
  width: 120px;
}

.Yallow-color-carcil {
  width: 300px;
}

.LOginLogo-main-div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  position: absolute;
  top: 45%;
  gap: 1rem;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}

.Are-you-a-span {
  color: #16151d;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
}

.Yallow-main-potitomn {
  position: relative;
}

.icon-main-circle {
  height: 50px;
  width: 40px;
}

.circle-icon-user {
  position: absolute;
  top: 10%;
  cursor: pointer;
  right: 27%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle-icon-Patient {
  position: absolute;
  top: 60%;
  cursor: pointer;
  right: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Corporate-btn-main {
  height: 45px;
  width: 339px;
  border-radius: 4px;
  background-color: #e5eeff;
  margin-top: 3rem;

  color: #16151d;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 600;
}

.Physician-span {
  color: #16151d;
  font-size: 18px;
  font-weight: 700;
}

.Homrimg-Splash-screen2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

/* .Yallow-color-carcil {
  transform: rotate(22deg);
}

.circle-icon-user {
  right: 20%;
  top: 20%;
}

.circle-icon-Patient {
  right: 60%;
  top: 50%;
} */

.Yallow-color-carcil {
  transform: rotate(10deg);
}

@media screen and (max-width: 1380px) {
  .Yallow-color-carcil {
    transform: rotate(18deg);
  }
}

@media screen and (max-width: 1240px) {
  .Yallow-color-carcil {
    /* width: 100%; */
    transform: rotate(23deg);
  }
  /* .LOginLogo-main-div {
    top: 45%;
  } */

  .circle-icon-user {
    right: 20%;
    top: 20%;
  }

  .circle-icon-Patient {
    right: 60%;
    top: 50%;
  }
}

@media screen and (max-width: 575px) {
  .Yallow-color-carcil {
    /* width: 100%; */
    transform: rotate(28deg);
  }
}
@media screen and (max-width: 378px) {
  .Yallow-color-carcil {
    width: 260px;
    transform: rotate(28deg);
  }
  .circle-icon-user {
    right: 10%;
  }
}
