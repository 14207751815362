.AvailabilityDoctor-main-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AvailabilityDoctor-main-div {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 2rem;
}

.selact-deta-div {
    display: flex;

}

.Save-Pay-btn {
    background: #9C04F5;
    border-radius: 4px;
    color: white;
    border: none;
    width: 200px;
    height: 45px;
    margin-top: 2rem;
    outline: none;
}

.react-datepicker {
    border: none !important;
    background-color: #282731 !important;
    color: #FFFFFF !important;
}

.react-datepicker__header {
    background-color: #282731 !important;
    border-bottom: none !important;

}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #FFFFFF !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: #FFFFFF !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 2.7rem;
}

.react-datepicker__month-container {}

.Wed-date-div {
    display: flex;
    flex-direction: column;
    height: 59px;
    width: 63px;
    border-radius: 6px;
    background-color: #3620FF;
    justify-content: center;
}

.Wed-date-div-main-douo {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    gap: 2rem;
}

.your-Availability-div {
    color: #16151D;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 27px;
}

.fifth-span {
    color: #FFFFFF;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
}

.wed-thas-tus-span {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
}

.Open-time-port {
    color: #16151D;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
}

.From-all-time-div {
    display: flex;
    flex-direction: column;
}

.From-handleRemoveInputSet-time-div {
    display: flex;
    gap: 1rem;
}

.Open-Time-time {
    height: 45px;
    width: 144px;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 1rem;
}

.from-to-main-div {
    display: flex;
    gap: 1rem;
    align-items: center;
}


.Green-main-plush-img {
    height: 30px;
    width: 30px;
    margin-top: 1.5rem;
}

.from-to-main-inputSet-div {
    display: flex;
    gap: 1rem;

    flex-direction: column;
}

.index-div {
    display: flex;
    flex-direction: column;
}

.from-to-main-div-index {
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.Save-butn-main-save {
    height: 45px;
    width: 339px;
    border-radius: 4px;
    background-color: #3620FF;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    border: none;
    outline: none;
    text-align: center;
    margin-top: 2rem;
}

.dash-add-line {
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border: 1px dashed #CDCDCD;
    margin-top: 2rem;

}

.add-date-time-Availability {
    display: flex;
    flex-direction: column;
}

.Wed-date-div-PM--am-main-douo {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 2rem;
    height: 59px;
    width: 343px;
    border-radius: 6px;
    background-color: #F1F6FF;

}

.AvailabilityDoctor-main-div {}

@media screen and (max-width: 429px) {
    .AvailabilityDoctor-main-div {
        width: 85%;
    }
}