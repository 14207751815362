.Feed_span_text_divv {
  font-weight: 500;
  color: rgb(19, 19, 186);
}

.July_main_tagg_text_divv {
  font-size: 22px;
  font-weight: 500;
}

.View_imgg_Full_month {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  align-items: center;
}

.July_main_divv {
  display: flex;
  align-items: center;
}

.July_main_tagg_divv {
  display: flex;
  gap: 15px;
}

.Arrow_btnn_left {
  background-color: #fff;
  border: none;
}

.Calender_Month_secondary_divv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Calender_main_divv {
  width: 100%;
}

.Twent_july_spann_divv_swiper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fff;
  height: 90px;
  width: 70px;
  justify-content: center;
  cursor: pointer;
}

.Swiper-main-imgg-home-divv-ppx {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.Swiper-main-imgg-main-divv {
  width: 100%;
  height: 100%;
}

.selected-date {
  background-color: rgb(19, 19, 186);
  color: white;
}

.greyed-out {
  background-color: #e1e1e1;
  color: #b0b0b0;
}

.mySwiper-divv-main1 {
  width: 100%;
  position: relative;
  margin-top: 2rem;
  border: none;
}

.mySwipermain-divv-ppx {
  border-radius: 5px;
  width: 470px;
  height: 100%;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  display: none;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  display: none;
}

.Swiper-short-image-home-divv {
  width: 50%;
  margin-left: 4px;
}

.Slider-images-divv-ppx {
  width: 70px;
}

.Slider-images-iip {
  width: 80px;
}

.Swiper-short-image-home-divv {
  width: auto;
  height: auto;
}

/* .swiper-slide img {
    width: 488px !important;
  } */
.slideeeimg {
  border: 2px solid #cfcfcf;
  border-radius: 5px;
}

.Swiper-main-scroll-Divv {
  display: flex;
  justify-content: center;
}

.sliderdiv {
  width: 90%;
  height: 100%;
  min-height: 125px;
  /* border: 1px solid red; */
  overflow: hidden;
}

.Swiper_Calender_Month_secondary_divv {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.previmg > img {
  width: 100%;
  border-radius: 12px;
}

.left-swiper-btn {
  width: 33px;
  height: 33px;
  background-color: white;
  border: 1px solid #cfcfcf;
  border-radius: 40px;
  padding: 3px;
}

.left-swiper-btn :hover {
  border-radius: 25px;
}

.sipleimg {
  /* width: 60px;
    height: 60px; */
  border-radius: 5px;
  opacity: 0.5 !important;
  display: block;
  width: 85%;
  height: 55%;
  object-fit: cover;
  background: #fffae2;
}

.previmg {
  width: 100%;
  height: 350px;
}

.previmg > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.slideeeimg {
  width: 60px;
  height: 60px;
}

.overflowdiv {
  display: flex;
  gap: 2rem;
}

.swiper-slide-active {
}

.swiper-slide-visible {
  opacity: 1 !important;
}

.react-datepicker {
  position: absolute;
  z-index: 9999;
  background-color: #fff;
}

.MAin_claender_divv {
  width: 100%;
}

.Month_View_Menu {
}

.Check_button_next_order {
  width: 135px;
  height: 40px;
  background-color: rgb(19, 19, 186);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
}

@media screen and (max-width: 1440px) {
  .July_main_divv {
    display: flex;
    align-items: center;
    gap: 18rem;
  }
}

@media screen and (max-width: 1280px) {
  .July_main_divv {
    display: flex;
    align-items: center;
    gap: 14rem;
  }
}

@media screen and (max-width: 912px) {
  .left-swiper-btn {
    width: 35px;
    height: 35px;
  }

  .sipleimg {
    width: 60px;
  }

  .slideeeimg {
    width: 45px;
  }
}

@media screen and (max-width: 885px) {
  .left-swiper-btn {
    width: 35px;
    height: 35px;
  }

  .sipleimg {
    width: 60px;
  }

  .slideeeimg {
    width: 45px;
  }

  .July_main_divv {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .Calender_Month_secondary_divv {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 820px) {
  .Home-slider-merge-divv {
    width: 100%;
  }
}

@media screen and (max-width: 428px) {
  .slideeeimg {
    width: 60px;
  }

  .sliderdiv {
    width: 100%;
    height: 100px;
    gap: 0.5rem;
  }

  .OrderMealSecond-div {
    width: 100%;
  }

  .Sales_Sann_para_spann_tagg {
    font-size: 14px;
  }

  .Calender_Month_secondary_divv {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .July_main_divv {
    flex-direction: column;
  }
}
