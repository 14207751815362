.Notifications-main-section {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

.Notifications-main-div {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.Doctor-main-dr-img {
  height: 72px;
  width: 72px;
  object-fit: cover;
  border-radius: 6px;
}

.Sunita-main-check {
  height: 25px;
  width: 25px;
  outline: none;
  cursor: pointer;
}

.Notifications-thord-contain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
}

.extra-dr-div {
  display: flex;
  gap: 2rem;
}

.Appointment-schedule-dr {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.red-background {
  background-color: #f3f2fd;
}

.Notifications-d-s-line {
  height: 0.5px;
  width: 100%;
  background-color: #171718;
}

.problem-span-Notifications {
  color: #16151d;
  font-weight: 500;
  font-size: 16px;
}

.Delete-main-img {
  width: 25px;
  height: 25px;
}

.Delete-main-span {
  color: #3620ff;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
}

.delete-main-div-all {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  bottom: 0px;
  background-color: #ffffff84;
  height: 50px;
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
}

.delete-main-div-all > div {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.paybtn2 {
  border: none;
  background-color: transparent;
  color: #3620ff;
  outline: none;
  width: 100px;
}

@media screen and (max-width: 884px) {
  .Notifications-main-div {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .Notifications-main-div {
    width: 90%;
  }
}

@media screen and (max-width: 428px) {
  .Notifications-main-div {
    width: 90%;
  }

  .extra-dr-div {
    gap: 1rem;
  }

  .problem-span-Notifications {
    color: #16151d;
    font-weight: 500;
    font-size: 15px;
  }
}
