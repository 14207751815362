.d-h-m-s-div {
  box-sizing: border-box;
  height: 26px;
  width: 190px;
  border: 1px solid #3620ff;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3620ff;
  font-size: 14px;
  font-weight: 600;
}

.Countdwon-img-sapn {
  height: 20px;
  width: 20px;
  margin-bottom: 0.1rem;
  margin-right: 0.3rem;
}
