.video-chat-consult-span {
    color: #E1A40A;
    font-size: 15px;
    font-weight: 600;
}

.fastar-mnb-CompleteAppointmentDetail {
    height: 25px;
    width: 25px;
}

.age-Consultation-span-add {
    color: blue;
    font-size: 16px;
    font-weight: bold;
}

.FindMD-img-contain-CompleteAppointmentDetail {
    display: flex;
    gap: 1rem;
    border-radius: 7px;
    padding: 1rem;
    width: 100%;
}

.Dorothy-Raja-sapn {
    color: white;
    font-size: 20px;
    font-weight: 700;
}

.Payment-all-contint-span {
    font-size: 15px;
    font-weight: 500;
}