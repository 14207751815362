.ConsultationHistory-Main-div {
    align-items: center;
    width: 80%;
}

.ConsultationHistory-main-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.maindata-pdf-section {
    width: 100%;

    display: flex;
    gap: 2rem;
}

.formated-main-date {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;

}