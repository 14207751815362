.Profileimg-Pratt-div {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.SelectProfile-main-div {
  display: flex;
  width: 80%;
  margin-top: 2rem;
}

.SelectProfileImg-and-name {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.Profileimg-main-img {
  height: 128px;
  width: 124px;
  border-radius: 4px;
  object-fit: cover;
}

.Lena-Pratt-span {
  color: #16151d;
  font-size: 16px;
  font-weight: 500;
}

.Lena-Pratt-span-1 {
  color: #16151d;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: 1150px) {
  .Profileimg-Pratt-div {
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
  }
}
@media screen and (max-width: 884px) {
  .Profileimg-Pratt-div {
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
  }
}

@media screen and (max-width: 835px) {
  .Profileimg-Pratt-div {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .Profileimg-Pratt-div {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 529px) {
  .Profileimg-Pratt-div {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.7rem;
    padding: 0;
  }
}
