.Update-Register-karo {
    width: 350px;
    height: 45px;
    border: none;
    outline: none;
    border-radius: 6px;
    background: #3620ff;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
}

.Information-main-add {
    font-size: 30px;
    font-weight: 600;
}

.YourProfile-next-Update {
    width: 100%;
    height: 100%;
    position: absolute;

}

.all-input-div-main-Information {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1000;
    top: 4rem;
}

.second-div-Doctor {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}