.model {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    z-index: 1000;
    padding: 0 1rem;
}

.model> :nth-child(1) {
    position: relative;
    top: 10px;
    z-index: -1;
}

.model>.closeicon> :nth-child(2) {
    animation: some 0.7s;
    width: 650px;
    border-radius: 10px;
}

.model>.closeicon> :nth-child(1) {
    animation: close 0.8s;
    cursor: pointer;
    align-self: flex-end;
}

@keyframes some {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.model>.closeicon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.closeicon>div>img {
    width: 100%;
}

.cardcontent {
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 1rem;
}

.popup-search {

    outline: none;
    border: none;
    margin-top: 1rem;
}

.line-div {
    height: 1px;
    width: 303px;
    background-color: #E7E7E7;

}

.Organization-sapn-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    cursor: pointer;
}

.Find-main-span {
    color: #16151D;
    font-size: 18px;
    font-weight: bold;
}

.close-main-input {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    height: 45px;
    width: 303px;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    background-color: #FFFFFF;
}

.close-serach-img {
    height: 24px;
    width: 24px;

    margin-left: 1rem;
}

.Organization-sapn {
    margin-top: 1rem;

    color: #16151D;

    font-size: 14px;
    font-weight: 500;

}

.closebtn {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.Organizations-main-span {
    font-size: 25px;
    font-weight: 600;
}

@keyframes close {
    0% {
        transform: rotate(150deg);
        opacity: 0;
    }

    100% {
        transform: rotate(0deg);
        opacity: 1;
    }
}

.textareadiv>textarea {
    resize: none;
    border-radius: 10px;
    border: 1px solid #d4d4d4;
    background: #fff;
    width: 100%;
    height: 70px;
    outline: none;
    padding: 10px 1rem;
}

.textareadiv>span {
    color: var(--black, #353535);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.textareadiv>button {
    align-self: center;
}

.shotoutdiv {
    width: 100%;
    position: relative;
}

.shotoutdiv>img {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 102px;
    height: 102px;
    z-index: 100;
    transform: translate(-50%, -50%);
}

.width-for-shoutin {
    width: 25% !important;
}

.search-main-Organizations {
    width: 300px;
    height: 40px;
    padding-left: 1rem;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(217, 211, 211);
}

@media screen and (max-width: 884px) {
    .width-for-shoutin {
        width: 35% !important;
    }
}

@media screen and (max-width: 834px) {
    .width-for-shoutin {
        width: 40% !important;
    }
}

@media screen and (max-width: 768px) {
    .width-for-shoutin {
        width: 45% !important;
    }
}

@media screen and (max-width: 730px) {
    .model>.closeicon> :nth-child(2) {
        width: 100%;
    }

    .textareadiv>textarea {
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .shotoutdiv>img {
        width: 50px;
        height: 50px;
    }

    /* #flparent {
      flex-direction: column;
    } */
    .width-for-shoutin {
        width: 90% !important;
    }
}

@media screen and (max-width: 415px) {}