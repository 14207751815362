.Login-section-main-div {
  width: 100%;
  position: relative;
  height: 100%;
}

.Login-section-main-div > :nth-child(1) {
  position: absolute;
  left: 1rem;
  top: 1rem;
  cursor: pointer;
}

.submit-div-he {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.Login-section-parent-div {
  display: flex;
  overflow: hidden;
  align-items: center;
  width: 100%;
  height: 100%;
}

.group28-img {
  width: 180px;
  height: 95px;
}

.align-self-div {
  align-self: flex-start;
  padding-left: 1rem;
}

.image-text-text23 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 400;
  width: 430px;
}

.color-span-span12 {
  color: #fd3e6c;
}

.login-img-img {
  /* width: 800px; */
  height: 730px;
  object-fit: cover;
}

.Blue-img {
  /* height: 400px;
  width: 750px; */
  position: relative;
  top: 11rem;
}

.Blue-main-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 3rem;
}

.backarrow-img {
  width: 24px;
  height: 24px;
}

.back-span-span {
  font-size: 15px;
  font-weight: 700;
  color: #1e2d38;
}

.back-span-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.login-section-all-input-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  position: absolute;
  bottom: 0rem;
  height: 100%;
}

.login-span-first {
  font-size: 27px;
  font-weight: 700;
  color: #1e2d38;
}

.email-logo {
  width: 24px;
  height: 24px;
}

.same-class-input-field {
  border: none;
  height: 35px;
  outline: none;
  width: 339px;
}

.Forgot-Password-span {
  font-weight: 500;
  color: #3620ff;
  cursor: pointer;
}

.margin-111-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
}

.OR-Login-With-span {
  font-size: 14px;
  font-weight: 400;
  color: #1e2d38;
}

.main-div-off-social-media-div22 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.social-width-media-div {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: #e0f2ff;
  color: #3620ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.smae-logo-for {
  width: 24px;
  height: 24px;
}

.only-register-span {
  color: #3620ff;
  font-weight: 600;
  cursor: pointer;
}

.pink_background {
  position: absolute;
  width: 700px;
  left: 80px;
  top: 558px;
}

.i-t-a {
  outline: none;
  width: 225px;
  height: 30px;
  border: none;
}

.svg-p-div {
  background: #ffffff;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  height: 45px;
  width: 339px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  /* justify-content: center; */
  padding-left: 1rem;
}

.chek-div-remember-me {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}

.che-div {
  display: flex;
  gap: 0.5rem;
}

.mastu-to-eroor {
  color: red;
}

.Apple-icon {
  height: 24px;
  width: 24px;
}

.Apple-img-span-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 150px;
  gap: 1rem;
  border: 1px solid #16151d;
  border-radius: 6px;
  background-color: #ffffff;
  cursor: pointer;
}

.Apple-and-Google-div {
  display: flex;
  gap: 1rem;
}

.chek-div-remember-me > a,
.dont-have-register-span > a {
  text-decoration: none;
}

@media screen and (max-width: 2015px) {
  .login-img-img {
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }

  .Blue-img {
    width: 100%;
    /* top: 14rem; */
  }
}

@media screen and (max-width: 1908px) {
  .login-img-img {
    width: 100%;
  }

  .Blue-img {
    width: 100%;
  }
}

@media screen and (max-width: 1280px) {
  .login-img-img {
    /* width: 660px; */
    width: 100%;
    /* height: 808px; */
  }

  .LOgin-Logo {
    width: 120px;
  }

  .Blue-img {
    height: 401px;
    width: 100%;
    position: relative;
  }

  .Login-section-main-div {
    height: 100vh;
  }
}

@media screen and (max-width: 1024px) {
  .login-img-img {
    /* width: 520px; */
    object-fit: cover;
  }

  .LOgin-Logo {
    width: 120px;
  }

  .Blue-img {
    height: 400px;
    width: 520px;
    position: relative;
    top: 11rem;
  }
}

@media screen and (max-width: 912px) {
  .Login-section-parent-div {
    flex-direction: column;
    gap: 2rem;
  }

  .login-img-img {
    width: 750px;
    height: 600px;
    display: none;
  }

  .login-img-img-div {
    display: flex;
    width: 100%;
  }

  .login-img-img {
    width: 100%;
  }

  .sado-hu-me {
    width: 100%;
  }

  .image-text-75 {
    gap: 4rem;
  }

  .login-section-all-input-div {
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    top: -3rem;
  }

  .dado-img-login-dono {
    width: 100%;
  }

  .pink_background {
    position: absolute;
    width: 646px;
    left: 125px;
    top: 432px;
  }

  .white_background {
    position: absolute;
    width: 100%;
    left: -1px;
    top: 411px;
  }

  .Blue-img {
    height: 700px;
    top: 21.5rem;
    width: 910px;
  }
}

@media screen and (max-width: 820px) {
  .Login-section-parent-div {
    flex-direction: column;
    gap: 2rem;
  }

  .login-img-img {
    width: 750px;
    height: 600px;
  }

  .login-img-img-div {
    display: flex;
    width: 100%;
  }

  .login-img-img {
    width: 100%;
  }

  .sado-hu-me {
    width: 100%;
  }

  .image-text-75 {
    gap: 4rem;
  }

  .login-section-all-input-div {
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    top: 6rem;
  }

  .dado-img-login-dono {
    width: 100%;
  }

  .pink_background {
    position: absolute;
    width: 646px;
    left: 125px;
    top: 432px;
  }

  .white_background {
    position: absolute;
    width: 100%;
    left: -1px;
    top: 411px;
  }

  .Blue-img {
    height: 700px;
    top: 21.5rem;
    width: 910px;
  }
}

@media screen and (max-width: 840px) {
  .login-section-all-input-div {
    position: absolute;
    height: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 24rem;
  }

  .submit-div-he {
    margin-top: 0rem;
  }
}

@media screen and (max-width: 770px) {
  .login-section-all-input-div {
    position: absolute;
    height: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 24rem;
  }

  .submit-div-he {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 580px) {
  .login-section-all-input-div {
    position: absolute;
    height: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 24rem;
  }

  .submit-div-he {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 540px) {
  .login-section-all-input-div {
    position: absolute;
    height: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 24rem;
  }

  .Login-section-main-div {
    height: 115vh;
  }

  .login-img-img {
    width: 100%;
    display: none;
    height: 100%;
  }

  .Blue-img {
    height: 450px;
    top: 13.5rem;
  }

  .LOgin-Logo {
    width: 120px;
  }

  .Blue-main-div {
    margin-top: 0rem;
  }

  .submit-div-he {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 428px) {
  .image-text-75 {
    align-items: center;
    gap: 0rem;
  }

  .white_background {
    position: absolute;
    width: 100%;
    left: -1px;
    top: 505px;
  }

  .login-section-all-input-div {
    position: absolute;
    height: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 24rem;
  }

  .Login-section-main-div {
    height: 100vh;
  }

  .login-img-img {
    width: 100%;
    display: none;
    height: 100%;
  }

  .Blue-img {
    height: 450px;
    top: 13.5rem;
  }

  .LOgin-Logo {
    width: 120px;
  }

  .Blue-main-div {
    margin-top: 0rem;
  }

  .submit-div-he {
    margin-top: 3rem;
  }

  /*  */
}

@media screen and (max-width: 415px) {
  .login-section-all-input-div {
    position: absolute;
    height: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 24rem;
  }

  .Login-section-main-div {
    height: 100vh;
  }

  .login-img-img {
    width: 100%;
    display: none;
    height: 100%;
  }

  .Blue-img {
    height: 450px;
    top: 13.5rem;
  }

  .LOgin-Logo {
    width: 120px;
  }

  .Blue-main-div {
    margin-top: 0rem;
  }

  .submit-div-he {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 376px) {
  .login-section-all-input-div {
    position: absolute;
    height: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .login-img-img {
    width: 100%;
    display: none;
    height: 100%;
  }

  .Blue-img {
    height: 650px;
    top: 13.5rem;
  }

  .LOgin-Logo {
    width: 120px;
  }

  .Blue-main-div {
    margin-top: 0rem;
  }

  .submit-div-he {
    margin-top: -2rem;
  }
}
