.Biochemistry-main-im {
    display: flex;
    flex-direction: column;
}

.days-main-div {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 1rem;
}

.type-days-span {
    color: rgb(180, 176, 176);
    font-size: 18px;
    font-weight: 600;
}

.in-put-days-type {
    display: flex;
    text-align: center;
    height: 50px;
    border-radius: 6px;
    outline: none;
    border: 1px solid rgb(181, 170, 170);
    width: 100px;
}

.Practitioner-main {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.in-put-days-type-Drug {
    display: flex;
    text-align: center;
    height: 50px;
    border-radius: 6px;
    outline: none;
    border: 1px solid rgb(181, 170, 170);
    width: 200px;
}

.in-put-days-type-Collected {
    display: flex;
    text-align: center;
    height: 50px;
    border-radius: 6px;
    outline: none;
    border: 1px solid rgb(181, 170, 170);
    width: 101px;
}

.in-put-days-type-Last {
    display: flex;
    text-align: center;
    height: 50px;
    border-radius: 6px;
    outline: none;
    border: 1px solid rgb(181, 170, 170);
    width: 90px;
}

.Hematology-main-span-div {
    width: 343px;
    background-color: rgb(216, 36, 66);
    height: 45px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.Hepatitis-main-span-div {
    width: 343px;
    background-color: rgb(43, 206, 209);
    height: 45px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.Immunology-main-span-div {
    width: 343px;
    background-color: rgb(159, 159, 159);
    height: 35px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.Hour-time-main {
    height: 40px;
    width: 165px;
    padding: 1rem;
    outline: none;
    border-radius: 3px;
    border: 1px solid rgb(181, 172, 172);
}

.Hour-time-main-div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.date-time-main-div {
    display: flex;
    gap: 1rem;
}

.PSA-totel-main-div {
    display: flex;
    gap: 1rem;
}