.Congratulations-main-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
}

.Congratulations-main-div {
    justify-content: center;
    display: flex;
    align-items: center;

    width: 80%;
    margin: 3rem;

}

.thard-con-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.con-img-w {
    height: 220px;
    width: 238px;

}

.successfully-div {
    width: 300px;
    display: flex;
    text-align: center;
}

.ok-btn {
    background:

        #3620FF;
    border-radius: 4px;
    width: 98px;
    height: 45px;
    color: #ffffff;
    border: none;
    outline: none;
    font-weight: 700;
    font-size: 15px;
}