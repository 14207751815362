.payment-XXXX-input67 {
    border: 1px solid #e4e4e4;
    width: 420px;
    height: 70px;
    gap: 1rem;
    outline: none;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 0px 15px 0px 15px;
    position: relative;
    background-color: rgb(227, 229, 230);

}

.last4-span-divv {
    font-size: 18px;
    font-weight: 700;
}