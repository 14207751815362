.Dizziness-main-div-itm {
  display: flex;
  width: 600px;
  justify-content: space-between;
  align-items: center;
}

.line-div-Dizziness {
  height: 1px;
  width: 600px;
  background-color: #e7e7e7;
}

.major-main-doc {
  width: 600px;
}

.Dizziness-sapn {
  color: #16151d;
  font-size: 16px;
  font-weight: 500;
  width: 290px;
}

@media screen and (max-width: 529px) {
  .major-main-doc {
    width: 350px;
  }

  .line-div-Dizziness {
    height: 1px;
    width: 350px;
    background-color: #e7e7e7;
  }

  .Dizziness-main-div-itm {
    display: flex;
    width: 350px;
    justify-content: space-between;
    align-items: center;
  }
}
