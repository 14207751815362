.Settings-main-section {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
}

.Settings-main-div {
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;

}

.Notifications-main-img {
    height: 27px;
    width: 27px;
}

.Push-noti-fication-div {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color:

        #3620FF;
}

input:focus+.slider {
    box-shadow: 0 0 1px #3620FF;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.togal-btn-puch-Notification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.Notifications-d-s-line {
    height: 2px;
    width: 100%;
    background-color: #E5E9F6;
}

.span-puchh-not {
    color: #16151D;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 21px;
}


@media screen and (max-width: 884px) {
    .Settings-main-div {
        width: 90%;
    }
}

@media screen and (max-width: 768px) {
    .Settings-main-div {
        width: 90%;
    }
}

@media screen and (max-width: 428px) {
    .Settings-main-div {
        width: 90%;
    }



}