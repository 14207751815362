.Subjective-input-2 {
    width: 100%;
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    outline: none;
}

.Dizziness-unfamiliar-line {
    height: 1px;
    width: 100%;
    background-color: #E5E9F6;
}

.span-Subjective-div {
    width: 100%;
    margin-top: 2rem;
}

.outcome-main-div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.outcome-main-div-Appoinmentin {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;

}

.Video-and-Audio-class {
    width: 99%;
    height: 44px;
    border: 1px solid #cfcfcf;
    outline: none;
    border-radius: 4px;
    padding-left: 1rem;
}

.outcome-Event-div {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Appoinment-Type-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 1rem;
}

.Doctor-span-Skip-Type {
    color: #16151D;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;

}

@media screen and (max-width: 428px) {
    .Subjective-input-2 {
        width: 90%;
    }

    .Dizziness-unfamiliar-line {
        width: 100%;
    }

    .span-Subjective-div {
        width: 90%;
    }

    .outcome-main-div {
        width: 90%;
    }
}