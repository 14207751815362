.Feed_span_text_divv {
  font-weight: 500;
}

.July_main_tagg_text_divv {
  font-size: 22px;
  font-weight: 500;
}

.View_imgg_Full_month {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
}

.July_main_divv {
  display: flex;
  align-items: center;

}

.swiper {
  padding: 23px !important;
}

.Calender_Month_secondary_divv {
  width: 100%;
  display: flex;
}

.Calender_main_divv {
  width: 100%;
}

.Twent_july_spann_divv_swiper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fff;
  height: 80px;
  width: 70px;
  justify-content: center;
  cursor: pointer;
}

.twenty_spann_span {
  font-size: 22px;
  font-weight: bold;
}

.July_spann_span {
  font-size: 12px;
  font-weight: 500;
}

/* .Twent_july_spann_divv_swiper:hover {
    background-color: #3620FF;
    color: #FFFFFF;
} */

.Swiper-main-imgg-home-divv-ppx {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.Swiper-main-imgg-main-divv {
  width: 100%;
  height: 100%;

  /* background-color: yellowgreen; */
}

.mySwiper-divv-main1 {
  width: 100%;
  position: relative;
  margin-top: 2rem;
  border: none;
}

.mySwipermain-divv-ppx {
  border-radius: 5px;
  width: 470px;
  height: 100%;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  display: none;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  display: none;
}

.Swiper-short-image-home-divv {
  width: 50%;
  margin-left: 4px;
}

.Slider-images-divv-ppx {
  width: 70px;
}

.Slider-images-iip {
  width: 80px;
}

.Swiper-short-image-home-divv {
  width: auto;
  height: auto;
}

.slideeeimg {
  border: 2px solid #cfcfcf;
  border-radius: 5px;
}

.Swiper-main-scroll-Divv {
  display: flex;
  justify-content: center;
}

.sliderdiv {
  width: 100%;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Swiper_Calender_Month_secondary_divv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.previmg>img {
  width: 100%;
  border-radius: 12px;
}

.left-swiper-btn {
  width: 30px;
  height: 32px;
  background-color: white;
  border: 1px solid #cfcfcf;
  border-radius: 40px;
  padding: 3px;
  z-index: 1000;
}

.sipleimg {
  /* width: 60px;
    height: 60px; */
  border-radius: 5px;
  opacity: 0.5 !important;
  display: block;
  width: 85%;
  height: 55%;
  object-fit: cover;
  background: #fffae2;
}

.previmg {
  width: 100%;
  height: 350px;
}

.previmg>img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.slideeeimg {
  width: 60px;
  height: 60px;
}

.overflowdiv {
  display: flex;
  gap: 2rem;
}

.swiper-slide-active {
  /* opacity: 2 !important; */
  /* border: 2px solid black !important; */
}

.swiper-slide-visible {
  opacity: 1 !important;
}

/* Add this CSS to your Calender.css or a separate CSS file */
/* Adjust the styles as needed to match your design */

/* Style for the date picker container */
.react-datepicker {
  position: absolute;
  /* You can adjust the positioning as needed */
  z-index: 9999;
  /* Ensure the date picker is above other elements */
  background-color: #fff;
  /* Background color */
}

/* @media screen and (max-width: 884px) {
    .mySwipermain-divv-ppx {
      width: 340px;
    }
  
    .Home-slider-merge-divv {
      display: flex;
      gap: 30px;
    }
  
  
    .sipleimg {
      width: 60px;
    }
  
    .slideeeimg {
      width: 55px;
    }
  
    .sliderdiv {
      width: 356px;
      height: 100px;
    }
  
    .left-swiper-btn {
      width: 35px;
      height: 35px;
    }
  } */
@media screen and (max-width: 912px) {
  .left-swiper-btn {
    width: 35px;
    height: 35px;
  }

  .sipleimg {
    width: 60px;
  }

  .slideeeimg {
    width: 45px;
  }
}

@media screen and (max-width: 884px) {
  .swiper-slide {
    margin-right: 0rem !important;
    width: 90px !important;
  }

  .swiper-slide {
    margin-right: 0rem !important;
  }
}

@media screen and (max-width: 768px) {
  .sliderdiv {
    width: 100% !important;
  }
}

@media screen and (max-width: 428px) {
  .sliderdiv {
    width: 100%;
    height: 100px;
  }

  .OrderMealSecond-div {
    width: 100%;
  }

  .swiper-slide {
    margin-right: 1rem !important;
  }
}

@media screen and (max-width: 391px) {
  .sliderdiv {
    width: 100%;
    height: 100px;
  }

  .OrderMealSecond-div {
    width: 100%;
  }

  .swiper-slide {
    margin-right: 1.5rem !important;
  }
}