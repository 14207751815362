.Main-Cancel-and-Pay {
    height: 45px;
    width: 100px;
    border-radius: 4px;
    border: none;
    outline: none;
    background-color: #3620FF;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: bold;
}

.handleCloseeee-main-hendel {
    display: flex;
    gap: 1rem;
}