.WaitingRoom-main-section {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.WaitingRoom-main-div {
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    align-items: center;
}

.WaitingRoom-logo-main-img {
    height: 103px;
    width: 103px;
}

.WaitingRoom-thard-img-co {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.img-logo-main-icon-conti {
    display: flex;
    gap: 1rem;
}

.taime-date-Wating {
    display: flex;
    flex-direction: column;
}

.Chest-date-Wating {
    display: flex;
    flex-direction: column;
}

.available-doctor-sapn-svg {
    margin-right: 0.5rem;
}

.Waiting-for-main-span {
    color: #16151D;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
}

.Patient-Mark-span {
    color: #16151D;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
}

.fith-tin-div {
    display: flex;
    gap: 2rem;
}

.five-handred {
    color: #16151D;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center;
}

.Number-span-one {
    color: #16151D;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
}

.Serving-Number-div-span {
    box-sizing: border-box;
    height: 79px;
    width: 164px;
    border: 1px solid #FFB802;
    border-radius: 6px;
    background-color: #FFFAEE;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Your-Number-div-span {
    box-sizing: border-box;
    height: 79px;
    width: 164px;
    border: 1px solid #3620FF;
    border-radius: 6px;
    background-color: #FAFAFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Reschedule-Number-Start-div {
    display: flex;
    gap: 2rem;
}