.handleTabClick-search {
    width: 150px;
    height: 35px;
    border-radius: 4px;
    background-color: #cfcfcf;
    border: none;
}

.handleTabClick-search.active {
    background-color: blue;
    width: 200px;
    height: 35px;
    border-radius: 4px;
    border: none;
}

.Search-main-buton-v {
    display: flex;
    gap: 1rem;
}

.handleTabClick-myMedicine.active {
    background-color: black;
}

.IoSearchOutline-main {
    width: 25px;
    height: 25px;
    margin-right: 0.5rem;
}

.IoSearchOutline-main-input {
    border: none;
    width: 330px;
    height: 40px;
    outline: none;
}

.IoSearchOutline-main-div-in {
    width: 350px;
    height: 45px;
    border: 1px solid #cfcfcf;
    outline: none;
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    border-radius: 4px;
}

.test-medical {
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
}

.line-haghit-div {
    width: 350px;
    height: 1px;
    margin-top: 1rem;
    border: 0.5px solid #ece6e6;
}

.IoSearchOutline-search-com {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Add-search-New {
    width: 300px;
    height: 45px;
    border-radius: 4px;
    border: none;
    background-color: blue;
    color: white;
    margin-top: 0.8rem;
}