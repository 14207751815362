.Otp-imge {
    /* height: 800px;
     */
    height: 100%;
    width: 100%;
}

.Blue-and-and {
    height: 500px;
    width: 100%;
    /* position: absolute; */
}

.mian-cunten-and-blue {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.Main-otp-input {
    position: absolute;
}

.main-blue-and-img {
    display: flex;
}

.otp-ForgotImg-img {
    width: 800px;
    height: 100%;
    object-fit: cover;
}



@media screen and (max-width:2015px) {
    .otp-blue {
        position: sticky;
        height: 64%;
        width: 100%;
        left: 0;
        /* top: -5rem; */

    }



    .otp-ForgotImg-img {
        width: 1000px;
    }

}

@media screen and (max-width:1795px) {
    .otp-blue {
        position: sticky;
        height: 64%;
        width: 100%;
        left: 0;
        /* top: -5rem; */

    }


    .otp-ForgotImg-img {
        width: 900px;
    }

}

@media screen and (max-width:1656px) {
    .otp-blue {
        position: sticky;
        height: 64%;
        width: 100%;
        left: 0;
        /* top: -5rem; */

    }



    .otp-ForgotImg-img {
        width: 750px;
    }

}


@media screen and (max-width:428px) {

    .otp-ForgotImg-img {
        /* width: 750px; */
        display: none;
    }

}