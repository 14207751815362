.Cardiology-main-sec {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-bottom: 3rem;
}

.Holter-monitor-with-div {
    display: flex;
    gap: 2rem;
    margin-left: 2rem;
    margin-top: 1rem;
}

.Cardiology-main-div {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;

}

.Reason-main-span {
    font-size: 20px;
    font-weight: 700;
}

.Cardiology-one-span {
    font-size: 18px;
    font-weight: 500;
}

.Reason-main-History-div {
    display: flex;
    gap: 1em;
    margin-top: 2rem;
    justify-content: space-between;
}

.Reason-one-div {
    width: 100%;
}

.all-radio-and-span {
    display: flex;
    gap: 2rem;
    margin-left: 2rem;
    /* color: white; */
    margin-top: 1rem;
}

.redio-ok-name {

    height: 20px;
    width: 20px;
}

.hors-taventh {
    font-size: 16px;
    font-weight: 600;

}

.radio-and-span {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

@media screen and (max-width: 884px) {
    .Reason-main-History-div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

    }

    .Cardiology-main-div {
        width: 90%;
    }
}

@media screen and (max-width: 768px) {
    .Reason-main-History-div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

    }

    .Cardiology-main-div {
        width: 90%;
    }
}

@media screen and (max-width: 428px) {
    .Reason-main-History-div {
        display: flex;
        flex-direction: column;
    }

    .Cardiology-main-div {
        width: 90%;
    }
}