.Health-input-div-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1000;
}

.Medical-next-blue {
  width: 100%;
  height: 875px;
  position: absolute;
  bottom: -28rem;
  left: 0;
}

.Volvo-selact-value {
  box-sizing: border-box;
  height: 45px;
  width: 116px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
}

.Unit-kg-selact {
  display: flex;
  flex-direction: column;
}

.rectangle-Weight {
  box-sizing: border-box;
  height: 45px;
  width: 217px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Weight-input-css {
  height: 40px;
  border: none;
  outline: none;
  width: 190px;
}

.BMI-MAin-input {
  background: #ffffff;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  height: 45px;
  width: 350px;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.BMI-MAin-input-div {
  display: flex;
  flex-direction: column;
}

.Bim-Md-main-input-css {
  outline: none;
  height: 40px;
  width: 320px;
  border: none;
}

.Submit-Main-btn-Bim {
  width: 350px;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 6px;
  background: #3620ff;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.Yes-btn-me-main {
  height: 45px;
  width: 168px;
  /* background-color: #FFB802; */
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
  color: #9aa1ab;
  font-size: 14px;
  font-weight: 600;
}

.Yes-and-No-name {
  display: flex;
  gap: 1rem;
}

.OtherInformation-Weight-error {
  color: red;
  width: 100px;
}

.bmi-Weight {
  color: red;
  font-size: 14px;
}

/* .Yes-btn-me-main:hover {
  height: 45px;
  width: 168px;
  background-color: #ffb802;
  border-radius: 4px;
  border: none;
  outline: none;
  color: white;
  font-size: 14px;
  font-weight: 600;
} */

@media screen and (max-width: 2010px) {
  .Medical-next-blue {
    width: 100%;
    bottom: -1.7rem;
    position: fixed;
    height: 475px;
  }
}

@media screen and (max-width: 884px) {
  .Medical-next-blue {
    bottom: -4rem;
  }
}

@media screen and (max-width: 820px) {
  .Medical-next-blue {
    bottom: -6rem;
  }
}

@media screen and (max-width: 800px) {
  .Medical-next-blue {
    bottom: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .Medical-next-blue {
    bottom: -15rem;
  }
}

@media screen and (max-width: 428px) {
  .Medical-next-blue {
    bottom: -31rem;
  }

  .Yes-btn-me-main {
    width: 100px;
  }
}

@media screen and (max-width: 391px) {
  .Medical-next-blue {
    bottom: -23rem;
  }
}

@media screen and (max-width: 360px) {
  .Medical-next-blue {
    bottom: -27rem;
  }

  .Yes-btn-me-main {
    width: 100px;
  }

  .BMI-MAin-input {
    width: 300px;
  }

  .Bim-Md-main-input-css {
    width: 280px;
  }

  .rectangle-Weight {
    width: 160px;
  }

  .Weight-input-css {
    width: 150px;
  }

  .hoooo {
    width: 90%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .Submit-Main-btn-Bim {
    width: 300px;
  }

  .condition-div-yes-no {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .Yes-btn-me-main:hover {
    width: 100px;
  }
}