.Availablity-main-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
}

.height-Availablity-main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 450px;
    height: 100%;
    padding: 1rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
    margin-bottom: 2rem;
}

.available-sapan-90 {
    font-size: 20px;
    font-weight: 700;
    color: #1e2d38;
}

.days-button-div {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.days-button {
    width: 120px;
    height: 40px;
    border-radius: 6px;
    color: #1e2d38;
    background-color: #fff;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid #dbdde2;
    outline: none;
}

/* .days-button:hover {
    background-color: lightgray;
    color: #1e2d38;
  } */
.days-button-on {
    width: 120px;
    height: 40px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #006ebd;
    color: #006ebd;
    font-size: 15px;
    font-weight: 700;
    outline: none;
}

/* .days-button-on:hover {
    background-color: lightgray;
    color: #1e2d38;
  } */
.available-sapan-90-div {
    display: flex;
    align-self: flex-start;
    margin-left: 1.8rem;
}

.Some-timing-for {
    font-size: 15px;
    font-weight: 600;
    color: #1e2d38;
}

.available-sapan-90-div-90 {
    display: flex;
    align-self: flex-start;
    align-items: center;
    gap: 4rem;
    margin-left: 1.8rem;
}

.button-virtual-button {
    width: 195px;
    height: 40px;
    outline: none;
    border: none;
    background-color: lightgray;
    color: #1e2d38;
    font-size: 15px;
    font-weight: 700;
    border-radius: 6px;
}

.button-virtual-button-op {
    width: 195px;
    height: 40px;
    outline: none;
    border: none;
    background-color: #006ebd;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    border-radius: 6px;
}

.save-button-090 {
    width: 386px;
    height: 40px;
    background-color: #001cbd;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 6px;
    font-weight: 700;
}


.time-slots {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(9, 1fr);
    gap: 1.5rem;
}

.button-virtual-button.active {
    background-color: lightgray;
    color: #1e2d38;
}

.button-virtual-button:not(.active) {
    background-color: #001cbd;
    color: #fff;
}

.button-virtual-button-op.active {
    background-color: lightgray;
    color: #1e2d38;
}

.button-virtual-button-op:not(.active) {
    background-color: #001cbd;
    color: #fff;
}

.days-button-on.active,
.days-button.active {
    background-color: #001cbd;
    color: white;
}




.Virtual-main-btn {
    background-color: #001cbd;
    color: white;
    width: 200px;
    height: 35px;
    border: none;
    border-radius: 5px 0px 0px 5px;

}

.Person-main-btn {
    height: 35px;
    width: 200px;
    border-radius: 0% 5px 5px 0px;
    background-color: #cfcfcf;
    border: none;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #006ebd;
}

input:focus+.slider {
    box-shadow: 0 0 1px #006ebd;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.Whole-Month-and-togle-divv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
}

@media screen and (max-width: 1024px) {
    .time-slots {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(7, 1fr);
        gap: 1.5rem;
    }

}

@media screen and (max-width: 912px) {
    .time-slots {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(6, 1fr);
        gap: 1.5rem;
    }

}

@media screen and (max-width: 820px) {
    .time-slots {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(6, 1fr);
        gap: 1rem;
    }

}

@media screen and (max-width: 768px) {
    .time-slots {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(5, 1fr);
        gap: 1.5rem;
    }

}

@media screen and (max-width: 540px) {
    .time-slots {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
    }

}

@media screen and (max-width: 431px) {
    .time-slots {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }

}

@media screen and (max-width: 391px) {
    .time-slots {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }

}