.headercontainer {
  background-color: #282731;
  padding: 0.5rem 1rem;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header > span {
  color: #ffffff;
}

.header > :nth-child(1) {
  cursor: pointer;
}
.header > :nth-child(3) {
  /* padding: 0 1rem; */
  cursor: pointer;
}
