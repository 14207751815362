.healthcarddivparent {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 2rem;
}

.healthcarddiv {
  display: flex;
  align-items: center;
  gap: 10px;
}

.healthcarddiv > img {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  object-fit: cover;
}

.healthcarddiv > div {
  display: flex;
  flex-direction: column;

  gap: 1rem;
}

.addcardmodel {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.addcardmodel > button {
  color: #000000;
}

.addhealthcardcontainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 10px 20px;
}

.fieldvalue {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fieldvalue > select {
  background-color: #ffffff;
  height: 45px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #dbdde2;
}

.fieldvalue > span {
  color: #000000;
  font-weight: 600;
}
.paybtn {
  background-color: transparent;
  border: none;
  font-weight: 400;
  margin: 8px;
}

@media screen and (max-width: 540px) {
  .healthcarddivparent {
    flex-direction: column;
  }

  .healthcarddiv > div {
    align-items: flex-start;
  }

  .healthcarddiv > div > h4 {
    font-size: 20px;
  }

  .healthcarddiv > div > h5 {
    font-size: 16px;
  }
}
