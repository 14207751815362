.All-Conatinor-perfect-divv {
  width: 100%;
  border-radius: 6px;
  background: #fff;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  z-index: 10000;
}

.All-Containor-perfect-second-divv {
  width: 100%;
  margin-top: 2rem;
}

.full-and-last-name {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.EditProfile-main-sectiom {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.EditProfile-main-div {
  width: 80%;
  display: flex;
  align-items: center;

  justify-content: center;
  margin: 3rem;
  flex-direction: column;
}

.Btn-Register-karo {
  width: 310px;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 6px;
  background: #3620ff;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.Profile-extra-div {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding: 0 1rem;
}

.Profile-main-Your {
  width: 90%;
  color: #16151d;
  font-size: 25px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.Upload-profile {
  width: 316px;
  color: #6a6975;

  font-size: 14px;
}

.YourProfile-next-blue {
  width: 100%;
  height: 500px;
  position: fixed;
  bottom: 0rem;
}

.backbtndiv {
  display: flex;
  flex-direction: column;
}

.pro-span {
  font-weight: 600;
  font-size: 24px;
  color: #211b24;
}

.pic-img {
  width: 140px;
  height: 140px;
}

.cemara-img {
  width: 45px;
  height: 45px;
}

/* .pic-div {
} */

.cemara-div {
  position: absolute;
  top: 6rem;
  left: 6rem;
}

.pic-cemara-div {
  width: 140px;
  position: relative;
  height: 140px;
}

.second-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.span-1 {
  font-weight: 400;
  font-size: 17px;
  color: #211b24;
}

.PhoneInputInput {
  outline: none !important;
  background: #ffffff !important;
  border-radius: 4px !important;
  border: none;
  /* color: #8d8d8d !important; */
}

.PhoneInputInput::placeholder {
  color: #8d8d8d !important;
  /* background: #8d8d8d !important; */
}

.phone-input {
  background: #f7f7f7;
  border-radius: 4px;
  padding: 0.5rem;
  width: 341px;
  height: 45px;
  display: flex;
  gap: 1rem;
  position: relative;
}

.icon-mar-gi-n {
  margin-left: 1rem;
}

.text-boxx-fullname {
  background: #ffffff;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  height: 45px;
  width: 310px;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.text-boxx-host {
  background: #ffffff;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  width: 343px;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  margin-right: 20px;
}

.PhoneInputInput::placeholder {
  color: #211b24 !important;
}

.PhoneInputCountry {
  gap: 0.5rem;
}

.mnb-phone {
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  /* justify-content: space-between; */
}

.dedra-input {
  width: 100%;
  outline: none;
  border: none;
  background: #f7f7f7;
}

.drop-book {
  outline: none;
  background: #f7f7f7;
  border-radius: 4px;
  width: 343px;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.all-input-div-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1000;
  top: 4rem;
}

.mastu-to-eroor {
  color: red;
  width: 195px;
  font-size: 15px;
  font-weight: 400;
}

.Full-all-Name-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.line-edit {
  width: 340px;
  height: 1px;
  border: 1px solid #dfdfdf;
  margin-top: 3rem;
}

.Govern-span {
  font-weight: 500;
  font-size: 14px;
  color: #211b24;
}

.host-span-tagg-ger {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.host-spann-divv-en {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.Addddddd-btn {
  background: #9c04f5;
  border-radius: 4px;
  width: 68px;
  height: 40px;
  color: white;
  border: none;
}

.Submit-btn {
  background: #9c04f5;
  margin-top: 2rem;
  border-radius: 4px;
  width: 355px;
  height: 40px;
  color: white;
  border: none;
}

.btn-spannnn-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
}

.mangal-khani {
  /* border: none;
              outline: none;
              position: absolute;
              left: 140px;
              width: 150px;
              background: #F7F7F7; */
  display: none;
}

.Ht-ml-for-imput {
  /* background: #f9f7fb; */
  /* margin-top: 1rem; */
  /* border: 1px solid #9C04F5; */
  border-radius: 100%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  object-fit: cover;
}

.pic-on-add {
  height: 120px;
  width: 120px;
  border-radius: 100%;
}

.Cemara-te-img {
  width: 40px;
  height: 40px;
  position: absolute;
}

.add-kiya-muja {
  position: absolute;
  object-fit: cover;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  border: none !important;
}

.full-name-bug-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}

.i-t-a-host-itm {
  outline: none;
  height: 40px;
  width: 144px;

  border: none;
}

.SandIcon-addeting-div {
  height: 60px;
  width: 40px;
  border-radius: 50px;
  position: absolute;
  top: 4.5rem;
  left: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

@media screen and (max-width: 884px) {
  .Btn-Register-karo {
    width: 315px;
    height: 45px;
  }

  .full-and-last-name {
    display: flex;
    flex-direction: column;
  }

  .text-boxx-fullname {
    width: 315px;
    height: 45px;
  }

  .i-t-a-host-itm {
    width: 255px;
  }

  .YourProfile-next-blue {
    bottom: 0rem;
  }

  .all-input-div-main {
    top: 7rem;
  }
}

@media screen and (max-width: 768px) {
  .Btn-Register-karo {
    width: 315px;
    height: 45px;
  }

  .full-and-last-name {
    display: flex;
    flex-direction: column;
  }

  .text-boxx-fullname {
    width: 315px;
    height: 45px;
  }

  .i-t-a-host-itm {
    width: 255px;
  }

  .YourProfile-next-blue {
    bottom: 0rem;
  }

  .all-input-div-main {
    top: 7rem;
  }
}

@media screen and (max-width: 428px) {
  .Btn-Register-karo {
    width: 315px;
    height: 45px;
  }

  .full-and-last-name {
    display: flex;
    flex-direction: column;
  }

  .text-boxx-fullname {
    width: 315px;
    height: 45px;
  }

  .i-t-a-host-itm {
    width: 255px;
  }
}

@media screen and (max-width: 415px) {
  .Btn-Register-karo {
    width: 315px;
    height: 45px;
  }

  .full-and-last-name {
    display: flex;
    flex-direction: column;
  }

  .text-boxx-fullname {
    width: 315px;
    height: 45px;
  }

  .i-t-a-host-itm {
    width: 255px;
  }
}
