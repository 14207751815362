.refillcontent > img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
}

.refillcontent {
  display: flex;
  gap: 1rem;
}

.refillcontent > div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.refillcontent > div > p {
  margin: 0;
}

.refillcontent > div > span {
  color: grey;
}

.refillcontentparentdiv {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 10px;
}

@media screen and (max-width: 540px) {
  .refillcontent > div {
    width: 80%;
  }
}
