.Person-main-btnop.active {
    background-color: #001cbd;
    color: white;
    width: 200px;
    height: 35px;
    border: none;
    overflow: hidden;

}

.Person-main-btnop {
    height: 35px;
    width: 200px;

    background-color: #cfcfcf;
    border: none;
}

.time-slots-avlebalhai {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 1.5rem;
}

.days-button-div-second {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(9, 1fr);
    gap: 1rem;
}

.Main-avlebal-pop {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}

@media screen and (max-width: 1024px) {
    .time-slots-avlebalhai {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(8, 1fr);
        gap: 1.2rem;
    }

    .days-button {
        width: 100px;

    }

    .days-button-on {
        width: 100px;
    }

    .days-button-div-second {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(8, 1fr);
        gap: 1rem;
    }

}

@media screen and (max-width: 912px) {
    .time-slots-avlebalhai {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(6, 1fr);
        gap: 1.5rem;
    }

    .days-button {
        width: 100px;

    }

    .days-button-on {
        width: 100px;
    }

    .days-button-div-second {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(6, 1fr);
        gap: 1rem;
    }

}


@media screen and (max-width: 820px) {
    .time-slots-avlebalhai {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(6, 1fr);
        gap: 1.5rem;
    }

    .days-button {
        width: 100px;

    }

    .days-button-on {
        width: 100px;
    }

    .days-button-div-second {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(6, 1fr);
        gap: 1rem;
    }

}


@media screen and (max-width: 768px) {
    .time-slots-avlebalhai {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(6, 1fr);
        gap: 1.5rem;
    }

    .days-button {
        width: 100px;

    }

    .days-button-on {
        width: 100px;
    }

    .days-button-div-second {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(6, 1fr);
        gap: 1rem;
    }

}

@media screen and (max-width: 540px) {
    .time-slots-avlebalhai {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(4, 1fr);
        gap: 1.5rem;
    }

    .days-button {
        width: 100px;

    }

    .days-button-on {
        width: 100px;
    }

    .days-button-div-second {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
    }

}

@media screen and (max-width: 431px) {
    .time-slots-avlebalhai {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5rem;
    }

    .days-button {
        width: 100px;

    }

    .days-button-on {
        width: 100px;
    }

    .days-button-div-second {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }

}

@media screen and (max-width: 428px) {
    .time-slots-avlebalhai {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5rem;
    }

    .days-button {
        width: 100px;

    }

    .days-button-on {
        width: 100px;
    }

    .days-button-div-second {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }

}


@media screen and (max-width:391px) {
    .time-slots-avlebalhai {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5rem;
    }

    .days-button {
        width: 100px;

    }

    .days-button-on {
        width: 100px;
    }

    .days-button-div-second {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }

    .Person-main-btnop {
        width: 170px;
    }

    .Person-main-btnop.active {
        width: 170px;
    }

    .save-button-090 {
        width: 350px;
    }
}