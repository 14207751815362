.FindMD-main-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.FindMD-main-div {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  width: 80%;
  gap: 2rem;
  height: 100%;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.Cardimg-docter {
  height: 100px;
  width: 100px;
  border-radius: 7px;
  object-fit: cover;
}

.FindMD-img-contain {
  display: flex;
  gap: 1rem;
  border-radius: 7px;
  padding: 2rem;
  border: 1px solid #71767f;
  width: 100%;
}

.Dorothy-extra-div {
  display: flex;
  flex-direction: column;
}

.Awy-div {
  height: 18px;
  /* width: 5ch; */
  border-radius: 2px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Lucas-awy-main-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  gap: 10px;
}

.Dorothy-Lucas-sapn-div {
  display: flex;
  flex-direction: column;
}

.Dorothy-sapn {
  color: #16151d;
  font-size: 20px;
  font-weight: 700;
}

.General-span {
  color: #71767f;
  font-size: 12px;
}

.time-img-css {
  height: 20px;
  width: 20px;
}

.Am-pm-span {
  color: #16151d;
  font-size: 13px;
  font-weight: 500;
}

@media screen and (max-width: 1284px) {
  .FindMD-main-div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 835px) {
  .FindMD-main-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
    gap: 1.5rem;
  }

  .FindMD-img-contain {
    display: flex;
    gap: 0.5rem;
    border-radius: 7px;
    padding: 1.5rem !important;
  }
}

@media screen and (max-width: 768px) {
  .FindMD-main-div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 95%;
    gap: 1rem;
  }

  .FindMD-img-contain {
    display: flex;
    gap: 0.5rem;
    border-radius: 7px;
    /* padding: 1.5rem !important; */
    width: 350px;
    padding: 0 !important;
  }
}

@media screen and (max-width: 529px) {
  .FindMD-main-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
  }

  .FindMD-img-contain {
    display: flex;
    gap: 1rem;
    border-radius: 0px;
    border-bottom: 1px solid #e5e9f6;
    border: none;
    /* padding: 1.5rem !important; */
  }
}

@media screen and (max-width: 415px) {
  .FindMD-main-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
  }

  .FindMD-img-contain {
    display: flex;
    gap: 1rem;
    border-radius: 0px;
    border-bottom: 1px solid #e5e9f6;
    border: none;
    /* padding: 1.5rem !important; */
  }
}

@media screen and (max-width: 391px) {
  .FindMD-main-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
  }

  .FindMD-img-contain {
    display: flex;
    gap: 1rem;
    border-radius: 0px;
    border-bottom: 1px solid #e5e9f6;

    /* padding: 1rem !important; */
  }

  .Lucas-awy-main-div {
    width: 180px;
  }
}

@media screen and (max-width: 376px) {
  .FindMD-main-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
  }

  .FindMD-img-contain {
    display: flex;
    gap: 1rem;
    border-radius: 0px;
    border-bottom: 1px solid #e5e9f6;
    border: none;
    /* padding: 1.5rem !important; */
  }

  .Lucas-awy-main-div {
    width: 180px;
  }
}
