.HealthDataDetail-main-section {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.HealthDataDetail-main-div {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profiledetail > * {
  color: #ffffff;
}

@media screen and (max-width: 884px) {
  .HealthDataDetail-main-div {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 428px) {
  .HealthDataDetail-main-div {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
