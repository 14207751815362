.Virtual-and-Person-Appointment-divee {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  height: 79px;
  width: 164px;
  border: 1px solid #e2e1ee;
  border-radius: 6px;
  background-color: #ffffff;
}

.Reschedule-main-btn {
  height: 45px;
  width: 339px;
  border-radius: 4px;
  background-color: #3620ff;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  border: none;
  outline: none;
}

.Reschedule-main-div {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Reschedule-main-div > button:disabled {
  background-color: gray !important;
}

@media screen and (max-width: 540px) {
  .Reschedule-main-btn {
    width: 100%;
  }
}
