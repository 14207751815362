.add-card-main-svg-div {
    display: flex;
    margin-top: 2rem;
    width: 100%;
    gap: 0.5rem;
}

.New-ard-add-span {
    color: #3620FF;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
}