.Patient-bono-main-div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
}

.Physician-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.immediately-div {
    display: flex;
    align-items: center;
    width: 82%;
    color: #16151D;

    font-size: 11px;
    letter-spacing: 0;
    line-height: 16px;
}

.thard-Sick-con-div-been {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
}

.cardcontent-patient-Torso-main-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    padding: 1rem;
}

.cardcontent-patient-Torso-main-div::-webkit-scrollbar {
    display: none !important;
}

.available-mbtn-with-pop {
    height: 45px;
    width: 331px;
    border-radius: 4px;
    border: none;
    outline: none;
    background-color: #3620FF;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: bold;
}