.NotesIcon1-main-img {
  height: 40px;
  width: 80px;
}

.NotesIcon1-and-name {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  border: 1px solid #c2c1c9;
  border-radius: 6px;
  padding: 1rem;
  background-color: #ffffff;
  cursor: pointer;
  width: 100%;
  min-width: 150px;
  height: 100%;
  min-height: 100px;
}
