.All-OtherInformation-perfect-divv {
  width: 100%;
  border-radius: 6px;
  border: 0.5px solid #ededed;
  background: #fff;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  /* margin-top: 2rem; */
  z-index: 10000;
}

.All-OtherInformation-perfect-second-divv {
  width: 100%;
  margin-top: 2rem;
}

.Information-main-div {
  background: #ffffff;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  border-radius: 4px;
  height: 45px;
  width: 343px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.OtherInformation-error {
  color: red;
  width: 250px;
}

.email-main-imnput-div {
  outline: none;
  height: 40px;
  width: 320px;
  border: none;
}

.Password-main-img {
  height: 24px;
  width: 24px;
}

.Location-main-img {
  height: 24px;
  width: 24px;
  margin-left: 1rem;
}

.Password-main-imnput-div {
  outline: none;
  height: 40px;
  width: 220px;
  border: none;
}

.clip-mar {
  margin-right: 1rem;
}

.Next-Register-karo {
  height: 45px;
  width: 343px;
  border: none;
  outline: none;
  border-radius: 6px;
  background: #3620ff;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.Gender-Male-Female-div {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.Gender-main-div {
  display: flex;
  gap: 1rem;
}

.Male-btn-main {
  height: 45px;
  width: 164px;
  border-radius: 4px;
  background-color: #ffb802;
  border: none;
  outline: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}

.Female-btn-main {
  height: 45px;
  width: 164px;
  background-color: white;
  /* background-color: #FFB802; */
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
  color: #9aa1ab;
  font-size: 14px;
  font-weight: 600;
}

.main-place-ho {
  width: 300px;
  /* position: relative; */
}

.Female-btn-mainactive {
  height: 45px;
  width: 164px;
  background-color: #ffb802;
  border-radius: 4px;
  border: none;
  outline: none;
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.all-input-form-div-main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  bottom: 0rem;
}

.full-Email-and-name-bug-div {
  display: flex;
  justify-content: center;
  gap: 0.3rem;
  flex-direction: column;
}

.Password-colum-div {
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

.mene-change-kiya-hai {
  height: 40px;
  width: 250px;
  display: flex;
  border: none;
  outline: none;
}

.mene-change-kiya-hai::placeholder {
  font-size: 15px;
  color: #919ba1;
  padding-left: 0.5rem;
  display: block;
}

.Location-main-place-div {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  /* height: 100%; */
}

.OtherInformation-next-blue {
  position: fixed;
  width: 100%;
  height: 475px;
  bottom: 0rem;
}

@media screen and (max-width: 2015px) {
  .OtherInformation-next-blue {
    width: 100%;
    bottom: -1.7rem;
    position: fixed;
  }

}

@media screen and (max-width: 912px) {
  .All-Containor-perfect-second-divv {
    margin-top: 0rem;
  }

  .OtherInformation-next-blue {
    width: 880px;
  }

  .Upload-profile {
    width: 290px;
  }

  .Female-btn-main {
    height: 45px;
    width: 170px;
  }

  .all-input-form-div-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    top: 2rem;
  }

  .Information-main-div {
    height: 45px;
    width: 360px;
  }

  .mene-change-kiya-hai {
    height: 40px;
    width: 280px;
  }

  .email-main-imnput-div {
    height: 40px;
    width: 180px;
  }

  .svg-p-div {
    height: 45px;
    width: 360px;
  }

  .Password-main-imnput-div {
    height: 40px;
    width: 254px;
  }

  .clip-mar {
    margin-right: 0rem;
  }

  .Next-Register-karo {
    height: 45px;
    width: 260px;
  }

  .OtherInformation-next-blue {
    bottom: 0rem;
  }

  .Password-colum-div {
    gap: 0rem;
  }

  .full-Email-and-name-bug-div {
    gap: 0rem;
  }

  .Location-main-place-div {
    gap: 0rem;
  }
}

@media screen and (max-width: 820px) {
  .All-Containor-perfect-second-divv {
    margin-top: 0rem;
  }

  .OtherInformation-next-blue {
    width: 760px;
  }

  .Upload-profile {
    width: 290px;
  }

  .Female-btn-main {
    height: 45px;
    width: 170px;
  }

  .all-input-form-div-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    top: 2rem;
  }

  .Information-main-div {
    height: 45px;
    width: 360px;
  }

  .mene-change-kiya-hai {
    height: 40px;
    width: 240px;
  }

  .email-main-imnput-div {
    height: 40px;
    width: 240px;
  }

  .svg-p-div {
    height: 45px;
    width: 360px;
  }

  .Password-main-imnput-div {
    height: 40px;
    width: 245px;
  }

  .clip-mar {
    margin-right: 0rem;
  }

  .Next-Register-karo {
    height: 45px;
    width: 260px;
  }

  .OtherInformation-next-blue {
    bottom: 0rem;
  }

  .Password-colum-div {
    gap: 0rem;
  }

  .full-Email-and-name-bug-div {
    gap: 0rem;
  }

  .Location-main-place-div {
    gap: 0rem;
  }
}

@media screen and (max-width: 428px) {
  .All-Containor-perfect-second-divv {
    margin-top: 0rem;
  }

  .OtherInformation-next-blue {
    width: 320px;
  }

  .Upload-profile {
    width: 290px;
  }

  .Female-btn-main {
    height: 45px;
    width: 120px;
  }

  .all-input-form-div-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .Information-main-div {
    height: 45px;
    width: 260px;
  }

  .mene-change-kiya-hai {
    height: 40px;
    width: 200px;
  }

  .email-main-imnput-div {
    height: 40px;
    width: 200px;
  }

  .svg-p-div {
    height: 45px;
    width: 260px;
  }

  .Password-main-imnput-div {
    height: 40px;
    width: 155px;
  }

  .clip-mar {
    margin-right: 0rem;
  }

  .Next-Register-karo {
    height: 45px;
    width: 260px;
  }

  .OtherInformation-next-blue {
    bottom: 16rem;
  }

  .Password-colum-div {
    gap: 0rem;
  }

  .full-Email-and-name-bug-div {
    gap: 0rem;
  }

  .Location-main-place-div {
    gap: 0rem;
  }
}

@media screen and (max-width: 415px) {
  .All-Containor-perfect-second-divv {
    margin-top: 0rem;
  }

  .OtherInformation-next-blue {
    width: 410px;
  }

  .Upload-profile {
    width: 290px;
  }

  .Female-btn-main {
    height: 45px;
    width: 120px;
  }

  .all-input-form-div-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    top: -1rem;
  }

  .Information-main-div {
    height: 45px;
    width: 260px;
  }

  .mene-change-kiya-hai {
    height: 40px;
    width: 200px;
  }

  .email-main-imnput-div {
    height: 40px;
    width: 200px;
  }

  .svg-p-div {
    height: 45px;
    width: 260px;
  }

  .Password-main-imnput-div {
    height: 40px;
    width: 155px;
  }

  .clip-mar {
    margin-right: 0rem;
  }

  .Next-Register-karo {
    height: 45px;
    width: 260px;
  }

  .OtherInformation-next-blue {
    bottom: -7rem;
  }

  .Password-colum-div {
    gap: 0rem;
  }

  .full-Email-and-name-bug-div {
    gap: 0rem;
  }

  .Location-main-place-div {
    gap: 0rem;
  }

  .main_div_sidebar {}
}

@media screen and (max-width: 395px) {
  .All-Containor-perfect-second-divv {
    margin-top: 0rem;
  }

  .OtherInformation-next-blue {
    width: 385px;
  }

  .Upload-profile {
    width: 290px;
  }

  .Female-btn-main {
    height: 45px;
    width: 120px;
  }

  .all-input-form-div-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .Information-main-div {
    height: 45px;
    width: 260px;
  }

  .mene-change-kiya-hai {
    height: 40px;
    width: 200px;
  }

  .email-main-imnput-div {
    height: 40px;
    width: 200px;
  }

  .svg-p-div {
    height: 45px;
    width: 260px;
  }

  .Password-main-imnput-div {
    height: 40px;
    width: 155px;
  }

  .clip-mar {
    margin-right: 0rem;
  }

  .Next-Register-karo {
    height: 45px;
    width: 260px;
  }

  .OtherInformation-next-blue {
    bottom: -2rem;
  }

  .Password-colum-div {
    gap: 0rem;
  }

  .full-Email-and-name-bug-div {
    gap: 0rem;
  }

  .Location-main-place-div {
    gap: 0rem;
  }

  .main_div_sidebar {}
}

@media screen and (max-width: 391px) {
  .All-Containor-perfect-second-divv {
    margin-top: 0rem;
  }

  .OtherInformation-next-blue {
    width: 385px;
  }

  .Upload-profile {
    width: 290px;
  }

  .Female-btn-main {
    height: 45px;
    width: 120px;
  }

  .all-input-form-div-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .Information-main-div {
    height: 45px;
    width: 260px;
  }

  .mene-change-kiya-hai {
    height: 40px;
    width: 200px;
  }

  .email-main-imnput-div {
    height: 40px;
    width: 200px;
  }

  .svg-p-div {
    height: 45px;
    width: 260px;
  }

  .Password-main-imnput-div {
    height: 40px;
    width: 155px;
  }

  .clip-mar {
    margin-right: 0rem;
  }

  .Next-Register-karo {
    height: 45px;
    width: 260px;
  }

  .OtherInformation-next-blue {
    bottom: -2rem;
  }

  .Password-colum-div {
    gap: 0rem;
  }

  .full-Email-and-name-bug-div {
    gap: 0rem;
  }

  .Location-main-place-div {
    gap: 0rem;
  }

  .main_div_sidebar {}
}

@media screen and (max-width: 376px) {
  .All-Containor-perfect-second-divv {
    margin-top: 0rem;
  }

  .OtherInformation-next-blue {
    width: 370px;
  }

  .Upload-profile {
    width: 290px;
  }

  .Female-btn-main {
    height: 45px;
    width: 120px;
  }

  .all-input-form-div-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .Information-main-div {
    height: 45px;
    width: 260px;
  }

  .mene-change-kiya-hai {
    height: 40px;
    width: 200px;
  }

  .email-main-imnput-div {
    height: 40px;
    width: 200px;
  }

  .svg-p-div {
    height: 45px;
    width: 260px;
  }

  .Password-main-imnput-div {
    height: 40px;
    width: 155px;
  }

  .clip-mar {
    margin-right: 0rem;
  }

  .Next-Register-karo {
    height: 45px;
    width: 260px;
  }

  .OtherInformation-next-blue {
    bottom: -10rem;
  }

  .Password-colum-div {
    gap: 0rem;
  }

  .full-Email-and-name-bug-div {
    gap: 0rem;
  }

  .Location-main-place-div {
    gap: 0rem;
  }

  .main_div_sidebar {}
}

@media screen and (max-width: 376px) {
  .All-Containor-perfect-second-divv {
    margin-top: 0rem;
  }

  .OtherInformation-next-blue {
    width: 370px;
  }

  .Upload-profile {
    width: 290px;
  }

  .Female-btn-main {
    height: 45px;
    width: 120px;
  }

  .all-input-form-div-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .Information-main-div {
    height: 45px;
    width: 260px;
  }

  .mene-change-kiya-hai {
    height: 40px;
    width: 200px;
  }

  .email-main-imnput-div {
    height: 40px;
    width: 200px;
  }

  .svg-p-div {
    height: 45px;
    width: 260px;
  }

  .Password-main-imnput-div {
    height: 40px;
    width: 155px;
  }

  .clip-mar {
    margin-right: 0rem;
  }

  .Next-Register-karo {
    height: 45px;
    width: 260px;
  }

  .OtherInformation-next-blue {
    bottom: -10rem;
  }

  .Password-colum-div {
    gap: 0rem;
  }

  .full-Email-and-name-bug-div {
    gap: 0rem;
  }

  .Location-main-place-div {
    gap: 0rem;
  }

  .main_div_sidebar {}
}