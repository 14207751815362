.circalcontainer-DoctorHome {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 1rem;
}

.forbackground {
  background-image: url("../../Assets/Images/young-handsome-physician-medical-robe-with-stethoscope\ \(1\).jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 11rem;
}

.homeheader {
  background-color: #282731;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.homeheader > :nth-child(1) {
  display: flex;
  align-items: center;
  gap: 10px;
}

#headername {
  color: #ffffff;
}

.homeheader > :nth-child(1) > img {
  width: 40px;
  border-radius: 50%;
  height: 40px;
}

.homeheader > :nth-child(1) > div > span {
  font-size: 13px;
}

.circalcontainer-DoctorHome > :nth-child(2) {
  width: 60%;
  display: none;
}

.circalcontainer-DoctorHome > :nth-child(2) > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.herosection {
  position: relative;
}

.herosection > p {
  position: absolute;
  bottom: 30%;
  width: 60%;
  color: #282731;
  padding: 0 1rem;
}

.circlediv-DoctorHome {
  position: relative;
  /* border: 1px solid red; */
  padding-top: 2rem;
}

.circlediv-DoctorHome > div {
  position: absolute;
  cursor: pointer;
  line-height: 20px;
}

.circlediv-DoctorHome > div > span {
  font-size: 13px;
  line-height: 10px !important;
}

/* Profile */
.circlediv-DoctorHome > :nth-child(2) {
  top: 20%;
  left: 39%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

/*   Health  Data */
.circlediv-DoctorHome > :nth-child(3) {
  top: 45%;
  left: 71%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 26%;
}

/* Settings */
.circlediv-DoctorHome > :nth-child(4) {
  top: 47%;
  left: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

/*  Find MD */
.circlediv-DoctorHome > :nth-child(5) {
  top: 33%;
  left: 10%;
}

/* Prescription  Renewal */
.circlediv-DoctorHome > :nth-child(6) {
  top: 68%;
  right: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 26%;
}

/* Health Card Payment/Insurance */
.circlediv-DoctorHome > :nth-child(7) {
  bottom: 23%;
  right: 58%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

/*   profile */
.circlediv-DoctorHome > :nth-child(8) {
  top: 47%;
  left: 47%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.bottombuttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  /* margin-top: 1rem; */
  /* padding-bottom: 1rem; */
}

.bottombuttons > button {
  box-sizing: border-box;
  height: 45px;
  width: 174px;
  /* border: 2px solid #dbd9e9; */
  /* border: none; */
  border-radius: 4px;
  background-color: #ffffff;
  font-weight: 700;
}

@media screen and (max-width: 990px) {
  .circalcontainer-DoctorHome > :nth-child(2) {
    width: 80%;
    display: block;
  }

  .circalcontainer-DoctorHome {
    width: 83%;
  }
}
