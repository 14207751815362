.All-black-main {
  background-color: black;
}

.Main-doctor-video-call-sec {
  display: flex;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Main-doctor-video-call-div {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.videocallbtn {
  position: absolute;
  bottom: 6rem;
  /* left: 50%; */
  right: 0rem;
  /* background-color: black; */
  transform: translate(-50%, -50%);
  display: flex;
  z-index: 1000;
  align-items: center;

  gap: 1rem;
}

.callername {
  position: absolute;
  top: 70%;
  left: 20%;
  display: flex;
  gap: 1rem;
  justify-content: space-around;
}

.callername > span {
  color: #ffffff;
  font-weight: 700;
}

.videocallbtn > span {
  cursor: pointer;
}

.callername > div {
  background-color: #ffffff;
  opacity: 0.8;
  height: 31px;
  width: 126px;
  display: grid;
  place-content: center;
  border-radius: 3px;
}

.videoshow {
  width: 100%;
  height: 100%;
}

.hours-main-minutes {
  width: 150px;
  font-size: 20px;
  background-color: rgb(142, 141, 141);
  border-radius: 7px;
  color: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  bottom: 2rem;
  right: 7rem;
}

.videocallbtn-and-count {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  bottom: 0rem;
  right: 36rem;
  width: 100%;
}

.Pattion-Show {
  width: 100%;
  height: 100%;
}

.video-container {
  position: relative;
}

.local-video-container video {
  width: 50%;
  height: 100%;
  object-fit: cover;
}

.patient_id-main-name {
  position: absolute;
  /* right:rem; */
  left: 35rem;
  bottom: 0rem;
  top: 0rem;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
}

.blank-screen {
  background-color: black;
  /* Or any other color you want for the blank screen */
}

.pation-video-container video {
  width: 50%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  right: 0rem;
}

/* Example styling for the countdown and buttons */

.FiCameraOff-main {
  height: 20px;
  width: 20px;
}

.IoMdMicOff-main {
  height: 25px;
  width: 25px;
}

.maicro-FiCameraOff-main-div {
  border-radius: 50px;
  height: 50px;
  width: 50px;
  background-color: rgb(78, 77, 77);
  display: flex;
  align-items: center;
  justify-content: center;
}

.CiEdit-Main-ico {
  height: 25px;
  color: #fff;
  width: 25px;
}

.CiEdit-Main-ico-div {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(142, 141, 141, 0.912);
  cursor: pointer;
  border-radius: 50px;
  position: absolute;
  right: 3rem;
  bottom: 0rem;
  top: 3rem;
  z-index: 10000;
}

.myCallContainermain {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1c1f2e;
  position: relative;
}
.myCallContainermain > div {
  width: 100%;
}

.editbtn {
  z-index: 1000000;
  position: absolute !important;
  top: 0rem;
  right: 1rem;
  font-size: 35px;
  border: none;
  width: 70px;
  height: 60px;
  background-color: transparent;
  border-radius: 10px;
}

@media screen and (max-width: 428px) {
  .pation-video-container video {
    width: 150px;
    height: 200px;
    object-fit: cover;
    position: absolute;
    top: 1rem;
    border-radius: 10px;
    right: 1rem;
  }

  .videocallbtn-and-count {
    right: 0rem;
    bottom: 0rem;
  }

  .hours-main-minutes {
    right: 7.5rem;
  }

  .local-video-container video {
    width: 100%;
  }
  .patient_id-main-name {
    top: 15rem;
    right: 1rem;
  }
}

@media screen and (max-width: 540px) {
  .videoshow {
    width: 100% !important;
  }
}
