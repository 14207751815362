.checkbox-main-div-itm {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.main-input-chek {
  height: 22px;
  width: 22px;
  margin-top: 1rem;
}

.Organization-AbdomenPelvic-main-div {
  display: flex;
  width: 85%;
  justify-content: space-between;
}

.AbdomenPelvic-main-span-k {
  width: 85%;
  margin-top: 0.5rem;
}

.Done-mbtn-with-pop {
  height: 45px;
  width: 303px;
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: #3620ff;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
}

.Done-mbtn-with-pop:disabled {
  background-color: grey;
}

.cardcontent-Torso-main-div {
  width: 100%;

  overflow-y: scroll;
  padding: 1rem;
}

.cardcontent-Torso-main-div::-webkit-scrollbar {
  display: none !important;
}

.expalin-div-sec {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.modal-content {
  width: 100% !important;
}

@media screen and (max-width: 884px) {
  .modal.show .modal-dialog {
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
  }
}

@media screen and (max-width: 768px) {
  .modal.show .modal-dialog {
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
  }
}

@media screen and (max-width: 428px) {
  .modal-content {
    width: 100% !important;
  }
}