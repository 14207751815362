* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mainpaymentiframe {
  width: 100% !important;
  height: 100% !important;
  min-height: 100vh;
}

/* body,
html {
  width: 100%;
  overflow-x: hidden;
} */

/* body {
  background-image: url("./Assets/Images/young-handsome-physician-medical-robe-with-stethoscope\ \(1\).jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  min-height: 100vh;
} */
