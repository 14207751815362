.input-div {
  height: 45px;
  width: 339px;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  outline: none;
  gap: 0.5rem;
  padding: 0px 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.input-div>input {
  width: 80%;
  border: none;
  outline: none;
  background: transparent;
}

.input-div>img {
  width: 24px;
  height: 24px;
}


@media screen and (max-width: 540px) {
  .input-div {
    width: 100%;
  }
}