.All-Conatinor-perfect-divv-andstate {
    width: 100%;
    border-radius: 6px;
    background: #fff;
    /* height: 100vh; */
    display: flex;
    /* justify-content: center; */
    z-index: 10000;
}

.all-input-custum-btn {
    height: 45px;
    width: 339px;
    border-radius: 4px;
    background-color: #3620FF;
    border: none;
    outline: none;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    margin-top: 3rem;
}

.all-input-div-main-DoctorRegister {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1000;
    top: 0rem;
}

@media screen and (max-width: 884px) {
    .all-input-div-main-DoctorRegister {
        top: 10rem;
    }

}

@media screen and (max-width: 768px) {

    .all-input-div-main-DoctorRegister {
        top: 10rem;
    }
}