.FindMD-main-div {
    /* ... other styles ... */
    cursor: pointer;
    /* Set cursor to pointer for indicating clickability */
}

.selected {
    background-color: #e0e0e0;
    /* Change background color when selected */

    /* Add a border when selected */
}

.FindMD-main-section-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 2rem;
}


.FindMD-main-div-head {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 80%;
    gap: 1rem;
    flex-direction: column;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;

}



.FindMD-img-contain-heading {
    border-radius: 7px;
    border: 1px solid #71767f;
    display: flex;

    padding: 1rem;
    gap: 1rem;
    height: 200px;

}

.main-Next-type-button {
    width: 339px;
    height: 45px;
    border-radius: 6px;
    color: #fff;
    font-size: 15px;
    background-color: blue;
    font-weight: 600;
    border: none;
    outline: none;
    cursor: pointer;
}


@media screen and (max-width: 1024px) {
    .FindMD-main-div-head {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 80%;
        gap: 3rem;
        flex-direction: column;
        margin-top: 1rem;
        align-items: center;
        justify-content: center;

    }

}

@media screen and (max-width: 820px) {
    .FindMD-main-div-head {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 80%;
        gap: 3rem;
        flex-direction: column;
        margin-top: 1rem;
        align-items: center;
        justify-content: center;

    }

}

@media screen and (max-width: 768px) {
    .FindMD-main-div-head {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 80%;
        gap: 2rem;
        flex-direction: column;
        margin-top: 1rem;
        align-items: center;
        justify-content: center;

    }

}


@media screen and (max-width: 541px) {
    .FindMD-main-div-head {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 80%;
        gap: 1rem;
        flex-direction: column;
        margin-top: 1rem;
        align-items: center;
        justify-content: center;

    }
}

@media screen and (max-width: 428px) {
    .FindMD-main-div-head {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 80%;
        gap: 1rem;
        flex-direction: column;
        margin-top: 1rem;
        align-items: center;
        justify-content: center;

    }

    .main-button {
        width: 90%;
    }

}