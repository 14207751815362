.main-button {
  width: 339px;
  height: 45px;
  border-radius: 6px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
}

.main-button:disabled {
  background-color: gray !important;
}

@media screen and (max-width: 540px) {
  .main-button {
    width: 100%;
  }
}
