.BillingDoctor-main-section {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.BillingDoctor-main-div {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 1rem;
}

.BillingDoctor-therd-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Hattie-main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Wed-date-div-main-Green {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 2rem;
    border: 1px solid #D8D7E6;
    border-radius: 6px;
    background-color: #FFFFFF;
}

.Transaction-main-history {
    display: flex;
    gap: 3rem;
}

.dolur-pirfthiton {
    margin-right: 2rem;
}

.Hattie-amin-span {

    color: #16151D;
    font-size: 14px;
    font-weight: 600;
}

.Transaction-ke-history-he {
    color: #16151D;
    font-size: 16px;
    font-weight: bold;
}

.main-span-doluor {
    color: #3400FF;
    font-size: 16px;
    font-weight: bold;
}

@media screen and (max-width: 428px) {

    .BillingDoctor-main-div {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1rem;
    }

    .Transaction-main-history {
        gap: 2rem;
    }

    .dolur-pirfthiton {
        margin-right: 1rem;
    }
}