.Physician-div-waves {
    display: flex;
    flex-direction: column;
}

.main-input-chek-being-available {
    display: flex;
    gap: 0.5rem;
    height: 103px;
    width: 327px;
}

.NO-main-btn-and {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.main-input-chek-Bono {
    height: 22px;

    margin-top: 1rem;
}