.Model-wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.8;
}

.ChangePassword-main-section {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  position: fixed;
  max-width: 25rem;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: white;
  max-height: 38rem;
}

.ekj-pAsword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Password-ek-or {
  display: flex;
  width: 90%;
}

.ChangePassword-main-div {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem;
  flex-direction: column;
  padding: 3rem;
}

.svg-pasword {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.svg-chaing-thard-ChangePassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 100;
}

.pasword-blue-img-div {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Reset-main-screen-btn {
  height: 45px;
  width: 339px;
  border-radius: 4px;
  background-color: #3620ff;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  border: none;
  outline: none;
}

.blue-h-w-img {
  height: 500px;
  width: 100%;
}

.border-line {
  width: 369px;
  height: 1px;
  border: 1px solid #e1e1e1;
  margin-top: 1rem;
}

.font-chang {
  color: #211b24;
  font-weight: 700;
  font-size: 20px;
}

.clip-mar {
  margin-right: 0.5rem;
}

.Reset-Password-btn-last {
  background: #9c04f5;
  border-radius: 4px;
  width: 343px;
  height: 45px;
  color: white;
  border: none;
}

.mastu-to-eroor {
  display: flex;
  align-items: flex-start;
  color: red;
  width: 95%;
  margin: 0 !important;
}

.Password-icon-main-img {
  height: 24px;
  width: 24px;
}

.all-of-Password-fild {
  display: flex;
  flex-direction: column;
}

.svg-NewPassword-div {
  background: #ffffff;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  height: 45px;
  width: 339px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  justify-content: center;
}

.svg-ConfirmNewPassword-div {
  background: #ffffff;
  border: 1px solid #dbdde2;
  border-radius: 6px;
  height: 45px;
  width: 339px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding-left: 1rem;
}

@media screen and (max-width: 884px) {
  .ChangePassword-main-section {
    width: 90%;
    max-height: 38rem;
  }

  .blue-h-w-img {
    height: 550px;
  }

  .Reset-Password-btn-last {
    width: 310px;
  }

  .svg-chaing-thard-ChangePassword {
    top: 5rem;
  }
}

@media screen and (max-width: 768px) {
  .ChangePassword-main-section {
    width: 90%;
    max-height: 38rem;
  }

  .blue-h-w-img {
    height: 550px;
  }

  .Reset-Password-btn-last {
    width: 310px;
  }

  .svg-chaing-thard-ChangePassword {
    top: 5rem;
  }
}

@media screen and (max-width: 428px) {
  .ChangePassword-main-section {
    width: 90%;
    max-height: 38rem;
  }

  .blue-h-w-img {
    height: 300px;
  }

  .Reset-Password-btn-last {
    width: 310px;
  }

  .svg-chaing-thard-ChangePassword {
    margin-top: 40px;
  }
}
