.addcardcontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  min-height: 90vh;
  height: 100%;
  padding: 0 1rem;
  margin-top: 1rem;
}

.effects {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
}

.effects>span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.effects> :nth-child(1) {
  background-color: #ffffff;
  opacity: 0.6;
}

.effects> :nth-child(2) {
  background-color: #ffffff;
  opacity: 0.4;
  position: relative;
  right: 0.5rem;
}

.carddetail {
  width: 350px;
  position: relative;
}

.detailss {
  position: absolute;
  top: 0;
  padding: 10px 10px;
}

.detailss>h3 {
  color: #ffffff;
  font-size: 22px;
  font-weight: 800;
  letter-spacing: 0;
}

.carddetail>img {
  width: 100%;
  object-fit: cover;
}

.detailss>div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.detailss>div>div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detailss>div>div>span {
  opacity: 0.9;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 0;
}

.fielddivcard {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: center;
  flex-direction: column;
}

.stripelement {
  width: 340px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Name-MAIN-CARD {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.main-Name-on-cardin {
  height: 40px;
  padding: 1rem;
  outline: none;
  border: 1px solid black;
  border-radius: 5px;
}

@media screen and (max-width: 540px) {
  .stripelement {
    width: 100%;
  }
}