.Consult-main-appointmentType {
    color: rgb(255, 200, 0);
    font-size: 15px;
    font-weight: 500;
}

.consultation-Choose-main-span {
    font-size: 15px;
}

.age-History-span-add {
    color: rgb(82, 82, 182);
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
}

.PdfAdd-Notes {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: center;
}