.Professional-body-div {
  display: flex;
  justify-content: space-between;
}

.No-body-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.Professional-body-div > span {
  display: block;
  width: 100%;
  height: max-content;
}

.Professional-body-div > :nth-child(3) {
  text-align: center;
  margin-left: 3rem;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
}
.Professional-body-div > :nth-child(4) {
  text-align: end;
}
