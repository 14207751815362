.Date-of-Birth-input-LMNP {
    padding: 1rem;
    outline: none;
    height: 45px;
    width: 343px;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #9AA1AB;
    letter-spacing: 0;
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
}

.here-input-be-answered {
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    outline: none;
    width: 343px;
}

.Ctscen-mri-div {
    display: flex;
    width: 343px;
    background-color: rgb(255, 255, 3);
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding: 1.5rem;

}

.Ctscen-main-div {
    width: 100%;
}

.contraindication-spn {
    width: 300px;
}

.checkdivMRI {
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;

}