.main-input-chek-being {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    height: 36px;
    width: 327px;
}

.main-input-chek-being-reassessment {
    display: flex;
    gap: 0.5rem;
    height: 36px;
    width: 327px;
}


.SickNote-main-thard-div {
    display: flex;
    flex-direction: column;
}

.Date-From-Birth-input {
    padding: 1rem;
    outline: none;
    height: 45px;
    width: 164px;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #9AA1AB;
    letter-spacing: 0;
    display: flex;
    gap: 1rem;
}

.date-main-input-he {
    display: flex;
    gap: 1rem;
}

.Enter-Days-main-div {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 1.5rem;
}

.BMI-Enter-MAin-input {
    background: #ffffff;
    outline: none;
    border: 1px solid #d1d1d1 !important;
    border-radius: 4px;
    height: 45px;
    width: 218px;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 0.7rem;
}

.Bim-Enter-main-input-css {
    outline: none;
    height: 40px;
    width: 200px;
    border: none;
}

.Enter-Dose-Name-value-main {
    box-sizing: border-box;
    height: 45px;
    width: 114px;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    background-color: #FFFFFF;
    outline: none;
}

.Sick-notes-input-2 {
    width: 85%;
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    outline: none;
}

.Btn-Sick-notes-karo {
    height: 45px;
    width: 355px;
    border: none;
    outline: none;
    border-radius: 6px;
    background: #3620ff;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
}



@media screen and (max-width: 428px) {
    .Sick-notes-input-2 {
        width: 100%;
    }
}