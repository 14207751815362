.Sick-NOte-mmain {
    height: 50px;
    width: 50px;
    color: rgb(219, 110, 110);
}

.FaRegFilePdf-FaRegFilePdf-span {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    border: 1px rgb(219, 110, 110) solid;
    /* background-color: rgb(225, 187, 187); */
    border-radius: 5px;
}

.Prescription-Give-main-btn {
    gap: 1rem;
    display: flex;
    margin-top: 1rem;
}

.FaRegFilePdf-main-notes-result {
    display: flex;
    gap: 1rem;
    overflow-x: scroll;
    width: 100%;
}

.Result-main-second-History {
    display: flex;
    justify-content: space-between;
    width: 100%;
}