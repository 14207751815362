.Reject-main-div {
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: center;
    gap: 0.2rem;

}

.Medicine-Name-dr-reject {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}