.RateDoctor-main-section {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.RateDoctor-main-div {
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
}

.RateThisProperty-sec {
  display: flex;
  flex-direction: column;

  border-radius: 15px;
  align-items: center;
  justify-content: center;
  position: fixed;
  max-width: 23rem;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: white;
  max-height: 36rem;
}

.thard-div-rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.RateThisProperty-main-divv {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem;
  flex-direction: column;
  padding: 2rem;
}

.rating-img {
  width: 100px;
  height: 85px;
  border-radius: 7px;
}

.Grandview-div {
  display: flex;
  width: 80%;
  gap: 1.5rem;
}

.usa-span-Skip {
  color: #3620ff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: right;
  cursor: pointer;
}

.Rate-karo-doc {
  display: flex;
  width: 100%;
}

.Malone-linnie-sapan {
  color: #16151d;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
}

.Doctor-span-Skip {
  color: #16151d;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
}

.us-usa-div {
  display: flex;
  gap: 4.5rem;
  width: 90%;
  justify-content: space-between;
  align-items: center;
}

.login-img-used {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.svg-chaing {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.Linnie-main-img {
  height: 76px;
  width: 76px;
  object-fit: cover;
  border-radius: 6px;
}

.us-span {
  font-weight: 500;
  font-size: 15px;
  color: #211b24;
}

.fastar-mnb {
  height: 40px;
  width: 40px;
}

.star-rating-div {
  display: flex;
  gap: 0.5rem;
}

.here-input-2 {
  width: 80%;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  outline: none;
}

.text-span-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.span-you-div {
  width: 80%;
  margin-top: 2rem;
}

.Submit-btn-div-22 {
  background: #211b24;
  border-radius: 4px;
  color: white;
  border: none;
  outline: none;
  width: 303px;
  height: 45px;
  font-weight: 700;
  font-size: 15px;
}

.btn-close-img-dono {
  outline: none;
  border: none;
  background-color: white;
}

.close-btn-img {
  width: 30px;
}

.Submit-mbtn-with-pop {
  height: 45px;
  width: 320px;
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: #3620ff;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
}
