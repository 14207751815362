.Speciman-main-span-div {
    width: 343px;
    background-color: rgb(115, 125, 174);
    height: 45px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.Setting-main-span-div {
    width: 343px;
    background-color: rgb(174, 176, 185);
    height: 45px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.Reason-main-Test-div {
    width: 343px;
    background-color: rgb(225, 35, 35);
    height: 45px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.Hepatitis-main-span-div {
    width: 343px;
    background-color: rgb(42, 192, 187);
    height: 45px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: #ffffff;
}

.Reason-Check-span {
    font-size: 18px;
    font-weight: 600;
}