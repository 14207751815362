.payment-main-section90 {
  width: 100%;
  height: 100%;
}

.payment-main-dib90 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.Payment-Options-span {
  font-size: 27px;
  font-weight: 700;
  color: #1e2d38;
}

.payment-input67 {
  border: 1px solid #e4e4e4;
  width: 420px;
  height: 60px;
  justify-content: space-between;
  outline: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0px 15px 0px 15px;
  position: relative;
  cursor: pointer;
}

.dustbn-logo-imgg-src {
  width: 30px;
  height: 30px;
}

.jugad-input12 {
  width: 300px;
  border: none;
  outline: none;
}

.visaimg-img {
  width: 63px;
  height: 39px;
  padding-left: 0.5rem;
}

.jugad-input12::placeholder {
  font-size: 14px;
  font-weight: 700;
  color: #1e2d38;
}

.paymnet-three-main-div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.paymnet-add-div {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Add-New-Card-span {
  font-size: 15px;
  font-weight: 500;
  color: #006ebd;
}

.add-logo-pls {
  width: 24px;
  height: 24px;
}

.dustbin-logo-black {
  display: flex;
  align-items: center;
}

.add-logocard-div99 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}


.payment-span-divv-head {
  width: 100%;
  margin-bottom: 1rem;
}

.Add-payment-btnn-div {
  height: 100px;
  display: flex;
  align-items: center;
}

.btn-pro-later-Pay {
  width: 100px;
  height: 40px;
  color: white;
  background-color: black;
  border-radius: 8px;
}

.pymaint-div-thard {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;
}

.isButton1Visible-main-div {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 1rem;
  top: 4rem;
  border-radius: 3px;
}

.Edit-isButton1Visible-main {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  gap: 1rem;
  height: 35px;
  width: 100px;
  border: 1px solid #c3c3c3;

  background-color: #ffffff;
}